main.artists-page {
	> .wysiwyg {

		> .content {
			> section {
				> .flex-wrapper {
					@media screen and (min-width: $row-width) {
						> .flex-row {
							margin: 0 -1rem;
							> article {
								max-width: 16rem;
							}
						}
					}

					> .flex-row {
						> article {
							flex-basis: 16rem;
							flex-grow: 1;

							margin: 0 1rem 2rem;
							> a {
								> .img {
									@include aspect-ratio(1,1);

									margin-bottom: 1rem;
								}
								> span.meta {
									display: block;
									text-align: center;
								}
							}
						}

						> .dummy {
							flex-basis: 16rem;
							margin: 0 1rem 2rem;

							max-width: 16rem;
						}
					}
				}
			}
		}
	}
}