#address_form {
	padding: 1rem;

	background-color: $color-light-gray;

	input[type="text"] {
		background-color: #fff;
		padding: 0.3rem;
		margin-left: 0.5rem;
	}
}