body.woocommerce-account.woocommerce-page {

	&.logged-in {
		&.woocommerce-orders main > .wysiwyg > .content  {

			.woocommerce {
				.woocommerce-MyAccount-content {
					.woocommerce-MyAccount-orders {
						margin: 2rem 0;
						> tbody {
							> tr {
								> td {
									text-align: center;
								}
							}
						}
					}
				}
			}
		}

		&.woocommerce-edit-account {
			> main {
				> .wysiwyg {
					> .content {
						> .woocommerce {

							.woocommerce-error {
								width: 100%;
								margin: 0 0 2rem;
							}

							> .woocommerce-MyAccount-content {
								background-color: $color-light-gray;
								padding: 1rem;

								> form {
									input[type="password"],
									input[type="email"],
									input[type="text"] {
										display: block;

										min-width: 15rem;

										padding: 0.3rem;
									}
								}
							}
						}
					}
				}
			}
		}

		> main {
			> .wysiwyg {
				> .content {
					> .woocommerce {
						display: flex;

						flex-flow: column wrap;

						@media screen and (min-width: $row-width) {
							flex-flow: row wrap;
							margin: 0 -2rem;

							> .woocommerce-MyAccount-navigation {
								padding: 1rem;
							}
						}

						@media screen and (min-width: $small-width) {
							> .woocommerce-MyAccount-content {
								padding: 0 1rem;
								table {
									border-spacing: 1rem;
									border-collapse: separate;
								}
							}
						}

						> .woocommerce-MyAccount-navigation {
							flex-basis: 15rem;

							background-color: $color-light-gray;
							padding: 1rem;

							> ul {
								margin: 0;
								li {
									list-style-type: none;
									padding: 0.5rem;

									&.is-active {
										background-color: $color-red;

										a {
											color: #fff;
										}
									}
								}
							}
						}

						> .woocommerce-MyAccount-content {
							flex-grow: 1;

							> .woocommerce-Message {
								display: flex;
								justify-content: center;
								align-items: center;
							}

							address {
								line-height: 1.5;
							}

							form {
								input[type="text"],
								input[type="email"],
								input[type="password"],
								text-area {
									background-color: #fff;
									border: 1px solid $color-gray;
								}
							}

							> .woocommerce-Addresses {
								> .woocommerce-Address {
									margin-bottom: 1rem;
								}
							}
						}
					}
				}
			}
		}
	}

	&:not(.logged-in) {
		> main {
			> .wysiwyg {
				> .content {
					> .woocommerce {
						background-color: #fff;
						> h2 {
							max-width: 30rem;
							margin: 0 auto 1rem;
						}

						>.col2-set {
							display: flex;
							justify-content: space-around;

							h2 {
								max-width: 30rem;
								margin: 0 auto 1rem;
							}
						}

						form {
							max-width: 30rem;
							margin: 0 auto;
							padding: 1rem 2rem;

							background-color: $color-light-gray;

							> p {
								display: flex;
								justify-content: space-between;
								flex-flow: column nowrap;

								> input[type="text"],
								> input[type="password"],
								> label {
									display: block;
									width: 100%;
								}

								> input[type="text"],
								> input[type="password"] {
									padding: 0.5rem 1rem;
								}

								> input[type="submit"] {
									margin: 1rem 0;

									width: 15rem;
									align-self: center;
								}

								&.lost_password {
									> a {
										text-align: center;
									}
								}
							}

							input[type="text"],
							input[type="email"],
							input[type="password"] {
								border: 1px solid black;
								background-color: #fff;
							}
						}
					}
				}
			}
		}
	}
}