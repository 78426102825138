body.page-template-page-galleries {
	> main {
		> .wysiwyg {
			> .content {
				> .flex-wrapper {
					> .flex-row {
						flex-flow: column wrap;

						> .slideshow-wrapper {
							margin: 3rem 0;

							> h3 {
								margin-bottom: 1rem;
							}

							> gecko-slideshow {
								> .viewport {
									> .slides {
										> gecko-slide {
											background-size: contain;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}