body.single-post {
	> main {
		> .clamped-width {
			> .wysiwyg {
				> h1 {
					margin: 3rem 0;
					padding-bottom: 1rem;

					border-bottom: 1px solid $color-light-gray;
				}

				> .primary {
					display: flex;
					flex-flow: row wrap;
					margin-bottom: 3rem;
					> .content {
						flex-basis: 25rem;
						flex-grow: 1;

						p:first-of-type {
							margin-top: 0;
						}
					}

					> .image {

						&.featured {
							flex-basis: 20rem;
							flex-grow: 1;

							margin: 0 1.5rem;

							height: 31.25rem;

							background-repeat: no-repeat;
							background-size: contain;
						}
					}
				}
			}
		}
	}
}