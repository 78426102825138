body > aside.touch-menu {
	position: fixed;
	top: 0;
	left: 0;

	z-index: 105;
	width: 80vw;
	max-width: 25rem;
	height: 100vh;

	display: flex;
	flex-direction: column;

	background-color: white;

	transition: left 0.3s ease-in-out;

	&[hidden] {
		left: -100%;
	}

	header {
		flex-shrink: 0;

		padding: 1rem;

		border-bottom: 1px solid black;
	}
	nav {
		flex-grow: 1;

		position: relative;

		font-size: 1.6rem;

		overflow: auto;
		overflow-x: hidden;

		background-color: $color-light-blue;

		.menu-primary-container {
			position: relative;
			height: 100%;

			#menu-primary {
				position: absolute;
				left: 0;

				width: 100%;

				transition: all 0.5s ease;

				&.offset {
					left: -100%;
				}

				li {
					display: block;
					width: 100%;

					a {
						position: relative;

						display: block;
						padding: 0.8rem 2rem 0.8rem 0.5rem;
						width: 100%;
					}

					&.current-menu-parent,
					&.current_page_item {
						background-color: $color-red;
						> a {
							color: #fff;
						}
					}
					// First layer active LI
					&.chosen {

						> .sub-menu {
							display: block;
							left: 100%;

							.parent {
								a {
									color: $color-red;
									border-bottom: 3px solid black;
									font-weight: bold;
									font-size: 1.5rem;
									margin-bottom: 1rem;

									&:after {
										display: none;
									}
								}
							}

						}
					}


					.sub-menu {
						position: absolute;
						left: 100%;
						top: 0;

						display: none;
						width: 100%;

						transition: all 0.5s ease;

						&.offset {
							left: 0;
						}

						li {

							a {
								font-size: 1.2rem;
							}
						}

						.sub-menu {
							position: absolute;
							left: 100%;
						}
					}

					&.menu-item-has-children {

						> a::after {
							position: absolute;
							top: 50%;
							right: 0.5rem;
							transform: translateY(-50%);

							content: "\f105";

							font-size: 2.5rem;
							font-family: fontawesome;
						}
					}


					&.menu-header-search {
						position: absolute;
						margin-top: 2rem;
						padding: 0.5rem;
						> form {
							position: relative;

							> input[type="text"] {
								display: block;
								width: 100%;

								background-color: #fff;

								padding: 0.3rem;
							}

							> input[type="submit"] {
								position: absolute;
								top: 0;
								right: 0;

								display: block;
								height: 100%;
								background-color: $color-red;
								color: #fff;

								padding: 0.3rem;
							}
						}
					}
				}
			}
		}
	}

	footer {
		display: flex;
		flex-shrink: 0;

		border-top: 1px solid black;
		padding: 1rem;

		background-color: $color-light-blue;

		&[data-active="true"] {
			#back {
				visibility: visible;
				position: relative;

				display: block;
				padding: 0 0 0 2.5rem;

				font-size: 1.2rem;
				text-transform: uppercase;

				&:before {
					position: absolute;
					left: 0.5rem;

					font-family: fontawesome;
					font-size: 1.2rem;
					content: "\f060";
				}
			}
		}

		#back {
			visibility: hidden;
		}
	}
}