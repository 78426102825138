body.page-template-page-contact-info {
	> main {
		iframe {
			width: 100%;
		}

		h4 + ul {
			margin-top: 1rem;
		}

		ul {
			margin-bottom: 1rem;

			li {
				margin: 0 1rem;
				padding: 0.5rem;
			}
		}

		> .wysiwyg {
			> .content {
				margin: 3rem 0;

				> .flex-wrapper {
					margin: 0 -1rem;

					> .flex-row {
						justify-content: space-between;
						> .col-left,
						> .col-right,
						> .sidebar {
							margin: 0 1rem;
						}

						> .sidebar {
							flex-basis: 15rem;
							flex-grow: 1;
							flex-shrink: 0;

							> p {
								@media screen and (min-width: $row-width) {
									margin: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}