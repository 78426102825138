main.woocommerce-page {
	> .wysiwyg {
		> .content {
			> h1 {
				margin-top: 1rem;
			}

			> .products {
				display: flex;
				flex-flow: row wrap;
				justify-content: space-between;

				margin: 1rem -3rem;

				> li {
					display: flex;
					flex-flow: column wrap;
					flex-basis: 17rem;
					flex-grow: 1;

					list-style-type: none;

					margin: 3rem;

					& .dummy {
						flex-basis: 17rem;
					}

					> a {
						display: flex;
						flex-flow: row wrap;
						flex-grow: 1;

						> img {
							max-width: 100%;
							padding-bottom: 1rem;
						}

						> .price {
							display: block;

							width: 100%;
							text-align: center;

							padding: 1rem;

							font-size: 1.2rem;
						}
					}

					> .button {
						flex-grow: 0;
						align-self: center;
					}
				}
			}
		}
	}
}