main.search {
	.clamped-width {
		margin-top: 3rem;
		margin-bottom: 3rem;

		> h1 {
			> .search-term {
				text-decoration: underline;
				font-style: italic;
			}
		}

		> .posts {
			margin-top: 1rem;

			li {
				display: flex;
				flex-flow: row wrap;

				&:not(:last-of-type) {
					margin-bottom: 4rem;
				}

				@media (max-width: $small-width) {
					flex-direction: column;
					margin: 0 0 4rem;
				}

				.img-placeholder {
					flex-basis: 18rem;
					flex-shrink: 0;

					display: block;
					height: 0;

					@media (max-width: $small-width) {
						flex-basis: auto;
					}
				}

				.img {
					@include aspect-ratio(6,1);
					flex-basis: 18rem;
					min-height: 16rem;
					flex-shrink: 0;

					display: block;

					background-position: top center;
				}

				section {
					flex-basis: 10rem;
					max-width: 28rem;
					flex-grow: 1;
					margin-left: 2rem;

					@media (max-width: $row-width) {
						margin-top: 1rem;
						margin-left: 1rem;
					}

					@media (max-width: $small-width) {
						flex-basis: auto;

						margin-left: 0;
						max-width: 100%;

						text-align: center;
					}

					h3 {
						display: block;
					}

					span {
						display: block;
						padding-top: 1rem;

						color: $color-dark-gray;
					}

					p {
						margin: 0 0 1rem;
						color: #333;
						line-height: 1.4;
					}

					a {
						color: $color-red;
						font-weight: 700;
					}

					.view-all-products {
						position: relative;

						display: inline-block;
						padding: 0.75rem 2.5rem;

						font-weight: normal;
						color: #FFF;

						transition: background 0.2s ease;
						background-color: $color-red;

						&::after {
							position: absolute;
							top: 50%;
							right: 1rem;
							transform: translateY(-50%);

							display: inline-block;
							margin: 0 0 0 1rem;
							height: 1em;

							font-family: fontawesome;
							content: "\f105";

							transition: all 0.2s ease;
						}

						strong {
							font-weight: bold;
						}

						&:hover {
							background-color: lighten($color-red, 3%);

							&::after {
								right: 0.8rem;
							}
						}
					}
				}
			}
		}
	}
}