body > header.desktop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

	background-color: #fff;
	z-index: 10;

	@include with-touch-nav {
		display: none;
	}

	.banner {
		padding: 0 2rem;
		text-transform: uppercase;
		background-color: $color-light-blue;

		> .flex-row {
			display: flex;
			justify-content: space-around;

			a {
				display: inline-block;
				position: relative;

				font-size: 1.2rem;

				padding: 1rem 2rem;

				&::before {
					position: absolute;

					font-family: fontawesome;
					text-align: center;

					color: $color-gray;
				}
			}

			> .left,
			> .right {
				display: block;
				width: 100%;
			}

			> .left {
				align-content: flex-start;

				> span {
					font-size: 1.2rem;

					&.account {
						> a {
							padding-left: 0;
							padding-right: 0;

							font-size: inherit;
							text-decoration: underline;
							color: $color-red;
						}
					}

					&.login {
						> a::before {
							left: 0.5rem;

							content: "\f007";
						}
					}
				}
			}

			> .right {
				display: flex;
				justify-content: flex-end;

				> .social {
					display: flex;

					border-left: 1px solid #fff;
					border-right: 1px solid #fff;

					> a {
						flex-grow: 1;

						i {
							color: $color-gray;
							transition: color 0.2s ease-in-out;

							&:hover {
								color: $color-red;
							}
						}

						&:hover {
							color: $color-red;
							&::before  {
								color: inherit;
							}
						}

						&::before {
							left: 0;

							width: 100%;
						}
					}
				}

				a {
					&.appt::before {
						left: 0.5rem;

						content: "\f017";
					}

					&.fb::before {
						content: "\f09a";
					}

					&.tw::before  {
						content: "\f099";
					}

					&.yt::before  {
						content: "\f16a";
					}

					&.cart {
						position: relative;

						padding: 1rem 2rem 1rem 4rem;

						&::before  {
							left: 2rem;

							content: "\f291";
						}

						&::after {
							position: absolute;
							right: 0.5rem;
							top: 0.5rem;

							transform: translateY(50%);

							font-family: fontawesome;
							content: "\f107";
						}
					}
				}
			}
		}

		a {
			color: #333;
		}
	}

	.branding {
		display: flex;
		justify-content: space-between;

		padding: 0 2rem;

		border-bottom: solid 1px $color-light-blue;

		> a {
			align-self: center;
		}

		nav {
			ul:not(.sub-menu) {

				> li {
					position: relative;

					display: inline-block;

					> a {
						padding: 2rem 1rem;
					}

					&:hover {
						> .sub-menu {
							display: flex;
							top: calc(100% + 1px); // Top padding + bottom padding = 2rem
							left: 50%;

							transform: translateX(-50%);

							min-width: 12rem;

							&::before {
								position: absolute;
								top: -10px;
								left: 50%;

								transform: translateX(-50%);

								height: 0;
								width: 0;

								content: "";

								border-style: solid;
								border-width: 0 10px 10px 10px;
								border-color: transparent transparent $color-red transparent;
							}
							> li.menu-item-has-children {
								position: relative;

								> a::after {
									position: absolute;
									right: 15%;

									content: "\f0da";
									font-family: fontawesome;
									font-size: 1rem;
									color: $color-red;
								}

								> .sub-menu {
									opacity: 0;

									position: absolute;
									top: 6px;
									left: 100%;

									width: 100%;

									padding: 0 0.1rem;

									> li {
										background-color: $color-light-gray;

										&:first-of-type {
											border-top: 1px solid $color-red;
										}

										&:hover {
											background-color: $color-gray-rock;
										}
										a {
											line-height: 2;
											padding: 0.5rem;
										}
									}
								}
								&:hover {
									> .sub-menu {
										opacity: 1;
										transition: opacity 0.2s linear;

										> li {
											> a {
												color: $color-red;
											}
										}
									}
								}
							}
						}
					}

					&.current-menu-item,
					&.current_page_parent,
					&.current-menu-parent {
						border-bottom: 1px solid $color-red;

						&::before {
							position: absolute;
							top: calc(100% - 10px);
							left: 50%;

							border: 1px solid $color-red;

							transform: translateX(-50%);

							height: 0;
							width: 0;

							content: "";

							border-style: solid;
							border-width: 0 10px 10px 10px;
							border-color: transparent transparent $color-red transparent;
						}
					}

					> .sub-menu {
						display: none;
						flex-flow: column nowrap;

						position: absolute;

						height: auto;
						width: auto;

						background-color: $color-light-blue;
						border-top: 1px solid $color-red;

						> li {
							> a {
								display: block;
								padding: 1rem;

								color: $color-red;

								&:hover {
									background-color: $color-gray-rock;
								}
							}
						}
					}

					> a {
						display: block;
						font-size: 1.125rem;
						color: #d74b2f;
					}

					&.menu-header-search {
						position: relative;
						padding: 1rem;



						&:hover {
							cursor: pointer;
						}

						&::before {
							content: "\f002";

							display: inline-block;

							font-family: fontawesome;
							color: $color-red;
							font-size: 1.2rem;
						}

						&.active {

							&::before {
								content: "\f00d";
							}

							> form {
								display: flex;
								flex-flow: row nowrap;

								border: 2px solid $color-gray;
							}
						}

						> form {
							display: none;

							position: absolute;
							top: 5rem;
							right: 0;

							transition: position 1s linear;

							> input {
								display: inline-block;

								padding: 0.5rem;

								background-color: #fff;

								&[type="submit"] {
									background-color: $color-red;
									color: #fff;
								}
							}
						}
					}

				}
			}
		}
	}
}