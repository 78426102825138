body.page-template-page-two-col-slideshow {
	> main > .wysiwyg {
		> gecko-slideshow {
			max-width: $row-width;
			margin: 3rem auto 0;
		}

		> .content {
			margin: 2rem 0;
			> .flex-wrapper {
				> .flex-row {
					display: flex;
					flex-flow: row wrap;
					@media screen and (min-width: $row-width) {
						margin: 0 -1rem;
					}
					> .col-left,
					> .col-right {
						flex-basis: 25rem;
						flex-grow: 1;

						padding: 1rem;

						> ul {
							> li {
								line-height: 2;
							}
						}
					}

					@media screen and (min-width: $row-width) {
						> .col-right {
							padding-left: 3rem;
						}
					}
				}
			}
		}
	}
}