%woo-message {
	padding: 0.325rem;
	margin: 0 0 1rem;

	text-align: center;

	border-radius: 3px;

	a.button, button {
		margin: 0 1rem;
	}
}
.woocommerce-message,
.woocommerce-error {
	@extend %woo-message;
}

ul.woocommerce-error {
	display: flex;
	flex-flow: column wrap;
	align-items: baseline;

	margin: 0 0 2rem 0;

	background-color: rgba($color-red, 0.1);
	border: 1px solid $color-red;

	> li {
		padding: 0.5rem;

		margin-left: 2rem;
	}

}

.shop_table {
	width: 100%;

	thead th {
		padding: 0.5rem;

		font-weight: bold;

		&.product-name {
			text-align: left;
		}

		&.product-subtotal,
		&.product-total {
			text-align: left;
		}
	}

	tbody .cart_item {

		td {
			padding: 0.5rem;

			text-align: center;
			vertical-align: middle;

			&.product-name {
				text-align: left;
				font-size: 1.2rem;

				a {
					display: block;
				}

				.variation {
					display: flex;
					padding: 0.25rem 0.5rem;

					font-size: 1rem;
					line-height: 1rem;
					color: #999;

					&:not(:last-of-type) {
						margin: 0 0 0.5rem;
					}

					> dt {
						margin: 0 0.5rem 0 0;
					}

					> dd {
						color: #666;
					}

					p {
						margin: 0;
						line-height: 1rem;
					}
				}
			}

			&.product-subtotal,
			&.product-total {
				text-align: left;
				font-size: 1.2rem;
			}
		}
	}

	tfoot tr {

		th {
			padding: 0.5rem;
			text-align: right;
		}

		td {
			padding: 0.5rem;

			ul, ol {
				margin: 0;
			}
		}
	}

	.cart-subtotal,
	.order-total {
		font-size: 1.2rem;
	}
}

.product-info-tabs {
	margin: 0 0 2rem;
	padding: 0 0 2rem;

	nav {
		display: flex;
		flex-wrap: wrap;

		[data-product-tab] {
			display: block;
			padding: 1rem 2rem;

			background-color: rgba(#E9E9E9, 0.5);

			&[data-status="active"] {
				color: white;
			}

			&:not(:last-of-type) {
				margin: 0 3px 0 0;
			}
		}
	}

	.tabs {

		[data-product-tab] {
			display: none;
			padding: 1rem;

			&[data-status="active"] {
				display: block;
			}

			p {
				margin: 0;

				&:not(:last-of-type) {
					margin: 0 0 0.5rem;
				}
			}
		}
	}
}

body.woocommerce-order-pay {
	#order_review {
		padding: 1rem;
	}

	#payment {
		input[type="submit"] {
			margin-top: 2rem;
		}
	}
}