body.page-template-page-news  {
	> main {
		.wysiwyg {
			> section.content-area {
				> .primary {
					> .content {
						margin-top: 3rem;

						> .flex-wrapper {
							margin: 0 -1rem;

							> section.flex-row {
								display: flex;
								justify-content: space-between;
								flex-flow: row wrap;

								> article {
									display: flex;
									flex-flow: row wrap;
									flex-basis: 30rem;

									flex-grow: 1;
									flex-shrink: 0;

									padding: 0 1rem;
									margin-bottom: 4rem;

									> .img {
										display: flex;
										flex-basis: 16rem;
										flex-grow: 1;

										min-height: 16rem;
										min-width: 16rem;

										// background-color: $color-light-gray;

										@media screen and (max-width: $row-width) {
											margin-bottom: 1rem;
										}

										> a {
											display: block;
											height: 100%;
											width: 100%;
										}
									}

									> .info {
										flex-basis: 26rem;
										flex-grow: 1;

										padding: 0 1rem;

										@media screen and (min-width: $row-width){
											padding: 0 0 0 2rem;
										}

										> span {
											display: block;

											&.author {
												font-size: 1.2rem;
												padding-bottom: 0.3rem;
											}

											&.date {
												color: $color-gray;
												padding-bottom: 1rem;
												border-bottom: 1px solid $color-light-gray;
											}
										}

										> .more {
											font-family: $bold-font-family;
											font-weight: bold;
											text-transform: uppercase;
										}

										> a h4 {
											padding: 0.5rem 0 0.8rem;
										}

										> p {
											margin: 0.5rem 0 0.8rem;
										}

										h4,
										p {
											text-align: left;
										}
									}
								}

								> .dummy {
									flex-basis: 30rem;
									flex-grow: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}