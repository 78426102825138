main.front {
	> .featured {
		margin-top: 3rem;

		> .slide-nav {
			> ul.text-links {
				> .text-link {
					> a {
						&[data-active="true"] {
							color: $color-red;
						}
					}
				}
			}
		}

		> .slide-info {
			display: flex;

			position: relative;

			> .container {
				width: 100%;

				&[data-active="false"] {
					display: none;
				}

				&[data-active="true"] {
					display: block;
				}

				@media screen and (min-width: $row-width) {
					transition: opacity 1s ease-in-out;

					&[data-active="false"] {
						opacity: 0;
						visibility: hidden;
					}

					&[data-active="true"] {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}

	}
}