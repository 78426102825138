body.post-type-archive-exhibition {
	> main {
		> .wysiwyg {

			> h1 {
				border-bottom: 1px solid $color-light-gray;
				padding-bottom: 3rem;
			}

			> .content {
				margin-top: 4rem;

				> .dates {
					display: flex;
					align-items: center;
					flex-flow: column wrap;

					margin: 4rem 0;

					> h4 {
						margin-bottom: 1rem;
					}

					> ul {
						display: flex;
						flex-flow: row wrap;

						> li {
							list-style-type: none;
							padding: 1rem 0;

							> a {
								color: $color-red;
								font-family: $bold-font-family;
								font-weight: bold;
								padding: 0 1rem;

								&.active {
									color: $color-dark-gray;
								}
							}
						}
					}
				}
			}
		}
	}
}