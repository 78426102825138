select {
	padding: 1rem;

	height: 3rem;
	min-width: 16rem;

	border-radius: 0;

	font-size: 1.2rem;
	color: #fff;
	background-color: $color-red;

	max-width: 10rem;

	&.hidden {
		visibility: hidden;
		opacity: 0;
	}
}

.filters > form {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-flow: row wrap;

	margin: 0 0 4rem;

	border-bottom: 1px solid $color-light-gray;

	> .separator {
		font-weight: bold;
		font-family: $bold-font-family;
		font-size: 1.3rem;
		text-transform: uppercase;
		color: $color-dark-gray;
		text-decoration: underline;
	}

	> label {
		display: flex;
		flex-basis: 15rem;
		flex-shrink: 0;

		flex-grow: 1;
		align-items: center;

		position: relative;

		padding: 2rem 0.5rem;

		font-weight: bold;
		font-family: $bold-font-family;
		font-size: 1.7rem;
		color: $color-dark-gray;


		&[for="filter_by"] {
			flex-basis: auto;
			// justify-content: flex-start;

			// font-size: 1.2rem;

			> .input-wrapper {
				display: flex;
				flex-flow: column wrap;

				padding-left: 0.5rem;

				> .row {
					display: flex;
					align-items: center;

					font-size: 1.3rem;

					&:not(:last-of-type) {
						margin-bottom: 0.5rem;
					}

					> input {
						margin-right: 0.5rem;


						&:hover {
							cursor: pointer;
						}
					}
				}

			}

			@media screen and (max-width: $row-width) {
				> span {
					margin-bottom: 0.8rem;
				}

				> .input-wrapper {
					.row {
						margin-bottom: 1rem;
					}
				}
			}
		}

		@media screen and (min-width: $row-width) {
			align-self: center;
			justify-content: center;
		}

		@media screen and (max-width: $small-width) {
			flex-flow: column wrap;
		}

		> select,
		> input {
			position: absolute;
			top: 50%;

			margin-left: 1rem;

			transform: translateY(-50%);
			opacity: 1;

			transition: opacity 0.3s ease-in-out;

			@media screen and (max-width: $small-width) {
				position: initial;
				top: 0;
				display: block;

				width: 100%;
				margin: 1rem 0 0;

				height: 4rem;

				transform: translateY(0);
			}
		}

		> input {
			border: none;

			background-color: $color-red;
			color: #fff;

			padding: 0.2rem 0.5rem;

			border-radius: 0.2rem;

			&.hidden {
				visibility: hidden;
				opacity: 0;
			}
		}

	}
}

.drop-list {
	@include material_shadow(1);

	position: absolute;
	margin-left: 0;

	max-height: 30vh;
	width: auto;

	overflow-y: scroll;

	background-color: #fff;

	> li {
		list-style-type: none;
		padding: 0.5rem;

		font-size: 1rem;

		&:hover {
			background-color: $color-red;
			color: #fff;
			cursor: pointer;
		}

		&.hidden {
			display: none;
		}

		&.active {
			background-color: $color-red;
			color: #fff;
		}
	}
	&.hidden {
		visibility: hidden;
	}
}

.chosen-container {
	position: relative;

	text-align: left;
	user-select: none;

	margin-left: 1rem;

	@media screen and (max-width: $small-width) {
		margin-top: 1rem;
	}

	.chosen-drop {
		position: absolute;
		z-index: 1010;
		top: 100%;
		left: -9999px;

		width: 100%;

		@include material-shadow(2);
	}

	&.chosen-with-drop .chosen-drop {
		left: 0;
	}

	a {
		cursor: pointer;
	}

	.search-choice .group-name,
	.chosen-single .group-name {
		margin-right: 4px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		font-weight: normal;
		color: #fff;

		&::after {
			content: ":";
			padding-left: 2px;
			vertical-align: top;
		}
	}
}

.chosen-container-single .chosen-single {
	position: relative;
	display: block;
	overflow: hidden;
	padding: 0 0 0 8px;

	height: 45px;

	background-color: $color-red;
	font-size: 1.5rem;

	color: #fff;

	text-decoration: none;
	white-space: nowrap;

	line-height: 45px;

	> span {
		color: #fff;
	}
}

.chosen-container-single .chosen-default {
	color: #999;
}
.chosen-container-single .chosen-single span {
	display: block;
	overflow: hidden;
	margin-right: 26px;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.chosen-container-single .chosen-single-with-deselect span {
	margin-right: 38px;
}
.chosen-container-single .chosen-single abbr {
	position: absolute;
	top: 6px;
	right: 26px;
	display: block;
	width: 12px;
	height: 12px;
	background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
	font-size: 1px;
}
.chosen-container-single .chosen-single abbr:hover {
	background-position: -42px -10px;
}
.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
	background-position: -42px -10px;
}
.chosen-container-single .chosen-single div {
	position: absolute;
	top: 0;
	right: 0;
	display: block;
	width: 18px;
	height: 100%;
}
.chosen-container-single .chosen-single div::before {
	content: "\f0d7";

	position: absolute;


	font-family: fontawesome;
	font-size: 1rem;

	color: #fff;

	height: 1rem;
	width: 1rem;
}

.chosen-container-single.chosen-with-drop .chosen-single div::before {
	content: "\f0d8";
}

.chosen-container-single .chosen-search {
	position: relative;
	z-index: 1010;
	margin: 0;
	padding: 3px 4px;
	white-space: nowrap;

	background-color: #fff;
}
.chosen-container-single .chosen-search input[type="text"] {
	margin: 1px 0;
	padding: 4px 20px 4px 5px;

	width: 100%;
	height: auto;

	font-size: 1em;
	font-family: sans-serif;
	line-height: normal;

	outline: 0;
	border: 1px solid #aaa;
	border-radius: 0;
}
.chosen-container-single .chosen-drop {
	margin-top: -1px;
	border-radius: 0 0 4px 4px;
	background-clip: padding-box;
}
.chosen-container-single.chosen-container-single-nosearch .chosen-search {
	position: absolute;
	left: -9999px;
}

/* @end */
/* @group Results */
.chosen-container .chosen-results {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;

	max-height: 240px;
	background-color: #fff;
	margin-left: 0;
	color: #EEE;

	-webkit-overflow-scrolling: touch;

li {
	display: none;
	margin: 0;
	padding: 0.5rem;

	list-style: none;
	line-height: 1rem;
	word-wrap: break-word;
	-webkit-touch-callout: none;


	&.active-result {
		display: list-item;
		cursor: pointer;

		color: $color-dark-gray;
		font-size: 1rem;
	}

	&.result-selected {
		display: list-item;
		color: #fff;
		background-color: $color-red;
		cursor: default;
	}

	&.disabled-result {
		display: list-item;
		color: #EEE;
		cursor: default;
	}

	&.highlighted {
		background-color: $color-red;
		color: #FFF;
	}

	&.no-results {
		padding: 0.5rem;
		display: list-item;

		font-size: 1.3rem;

		color: initial;

		> span {
			display: inline-block;
			padding: 1rem 0;
		}
	}

	&.group-result {
		display: list-item;
		font-weight: bold;
		cursor: default;
	}

	&.group-option {
		padding-left: 15px;
	}

	em {
		font-style: normal;
		text-decoration: underline;
	}
}
}

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
	position: relative;

	overflow: hidden;

	margin: 0;
	padding: 0 1.75rem 0.5rem 0.5rem;
	width: 100%;
	height: auto;
	min-height: 100%;

	border: 0;
	cursor: pointer;

	&::after {
		position: absolute;
		top: 10px;
		right: 10px;

		content: "\f0d7";

		font-size: 1.5rem;
		font-family: fontawesome;

		cursor: pointer;
	}

li {
	float: left;
	list-style: none;

	&.search-field {
		margin: 0;
		padding: 0;
		white-space: nowrap;

		input[type="text"] {
			display: inline-block;
			margin: 0.375rem 0 0;
			padding: 0.5rem 0 0;
			outline: 0;

			background: transparent;

			color: #999;
			font-size: 100%;
			font-family: sans-serif;
			line-height: normal;

			border: 0;
			box-shadow: none;
			border-radius: 0;
			cursor: pointer;
		}
	}

	&.search-choice {
		position: relative;

		margin: 0.5rem 0.5rem 0 0;
		padding: 0.5rem 1.5rem 0.5rem 0.5rem;
		max-width: 100%;

		color: #EEE;

		line-height: 13px;

		span {
			word-wrap: break-word;
		}

		.search-choice-close {
			position: absolute;
			right: 6px;
			top: 9px;

			display: block;
			width: 12px;
			height: 12px;

			font-size: 1px;

			&:hover {
				background-position: -42px -10px;
			}
		}
	}

	&.search-choice-disabled {
		padding-right: 0.5rem;
		background: transparent;
		text-transform: uppercase;
		color: #DDD;
	}

	&.search-choice-focus {
		background: #d4d4d4;

		.search-choice-close {
			background-position: -42px -10px;
		}
	}
}
}


/* @end */
/* @group Active  */
.chosen-container-active .chosen-single {
	background-color: $color-red;
}
.chosen-container-active.chosen-with-drop .chosen-single {


}
.chosen-container-active .chosen-choices {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

}

/* @end */
/* @group Disabled Support */
.chosen-disabled {
	opacity: 0.5;
	cursor: default;

	.chosen-single,
	.chosen-choices .search-choice .search-choice-close {
		cursor: default;
	}
}

/* @end */
/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
	.chosen-rtl .chosen-search input[type="text"],
	.chosen-container-single .chosen-single abbr,
	.chosen-container-single .chosen-single div b,
	.chosen-container-single .chosen-search input[type="text"],
	.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
	.chosen-container .chosen-results-scroll-down span,
	.chosen-container .chosen-results-scroll-up span {
		background-image: url('chosen-sprite@2x.png');
		background-size: 52px 37px;
		background-repeat: no-repeat;
	}
}