body.woocommerce-checkout {

		.woocommerce > form {
			background-color: $color-light-gray;

			> .woocommerce-error {
				display: flex;
				flex-flow: column wrap;
				align-items: baseline;

				margin: 0 0 2rem;

				background-color: rgba($color-red, 0.1);
				border: 1px solid $color-red;

				> li {
					padding: 0.5rem;

					margin-left: 2rem;

				}
			}

			input[type="email"],
			input[type="password"],
			input[type="text"],
			input[type="tel"],
			textarea,
			 {
				background-color: #fff;
			}

			&.checkout {
				margin-top: 3rem;
				padding: 1rem;
			}
		}


	.woocommerce > .woocommerce-info {
		margin: 0 0 1rem;

		text-align: center;
	}

	.woocommerce > .login {
		margin: 0 0 1rem;
		padding: 1rem;
		width: 100%;

		border-radius: 3px;

		p {
			margin: 0 0 0.5rem;

			&:last-of-type {
				margin: 0;
			}
		}
	}

	.woocommerce > form > .woocommerce-checkout-review-order {
		margin-bottom: 3rem;
		> table {
			> tbody {
				border-bottom: 1px solid $color-gray;
			}

			> tfoot {
				> tr {
					&.shipping {
						display: none;
					}

					> td {
						> .notice {
							padding: 0.7rem;
							width: 20rem;
							background-color: $color-notice-yellow;

							> .header {
								text-transform: uppercase;
								// font-weight: 700;
							}
						}
					}
				}
			}
		}
	}

	.woocommerce > .checkout_coupon {
		display: flex;
		padding: 1rem;

		.form-row {
			display: flex;
			margin: 0;
			float: none;
		}

		.clear {
			display: none;
		}

		.button {
			margin: 0;
		}
	}

	$col-break: 860px;
	.col2-set {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 2rem;

		@media (max-width: $col-break) {
			flex-direction: column;
		}

		> div {
			flex-grow: 1;
			flex-basis: 1rem;

			@media (max-width: $col-break) {
				flex-basis: auto;
			}
		}

		.col-1 {
			padding-right: 1rem;

			@media (max-width: $col-break) {
				margin-bottom: 1rem;
				padding-right: 0;
			}
		}

		.col-2 {
			padding-left: 1rem;

			@media (max-width: $col-break) {
				padding-left: 0;
			}
		}
	}

	.form-row {
		margin: 0 0 0.5rem;

		&.form-row-first,
		&.form-row-last {
			float: left;
			width: 50%;

			@media (max-width: 540px) {
				float: none;
				width: 100%;
			}
		}

		&.form-row-first {
			padding-right: 0.5rem;

			@media (max-width: 540px) {
				padding-right: 0;
			}
		}

		&.form-row-last {
			padding-left: 0.5rem;

			@media (max-width: 540px) {
				padding-left: 0;
			}
		}

		label {
			display: block;

			color: #666;
		}

		textarea,
		input[type="text"],
		input[type="tel"],
		input[type="password"],
		input[type="email"] {
			display: block;
			width: 100%;

			padding: 0.5rem 1rem;
		}

		&.woocommerce-invalid {
			textarea,
			input[type="text"],
			input[type="tel"],
			input[type="password"],
			input[type="email"] {
				border-color: $color-red;
				background-color: rgba($color-red, 0.1);
			}
		}
	}

	.select2-container .select2-choice > .select2-chosen {
		padding: 0.5rem;
	}

	.woocommerce-checkout-payment#payment {
		> .payment_methods {
			margin: 0 0 1rem;
			padding: 1rem 0 0;

			border: 0;

			> li {
				display: block;
				margin: 0;
				padding: 0;

				&:not(:last-of-type) {
					margin-bottom: 1rem;
				}

				input[type="checkbox"],
				input[type="radio"] {
					display: none;
				}

				> label {
					display: flex;
					align-items: center;
					padding: 1rem;

					cursor: pointer;
					font-size: 1.2rem;

					transition: all 0.3s ease;

					background-color: #FFF;

					&::before {
						display: block;
						width: 1.5rem;
						transition: all 0.2s ease;

						content: "\f10c";
						font-family: fontawesome;
					}

					img {
						display: inline-block;
						margin: 0 0.5rem;
					}
				}

				input:checked + label {

					&::before {
						content: "\f192";
					}
				}

				div.payment_box {
					margin: 0;
					padding: 0.5rem 1rem;

					border-top: 0;

					p {
						margin: 0 0 1rem;
					}
				}


				#wc-stripe-cc-form {

					> * {
						display: block;
						float: none;
						width: auto;

						input {
							width: auto;
						}
					}

					> .form-row-first,
					> .form-row-last {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}

		.place-order {

			.button {
				display: block;
				padding: 1rem 3rem;
				margin: 0 auto;
				font-size: 1.4rem;
			}
		}
	}
}