// Put SCSS for the front page here
main.front {
	max-width: $row-width;

	background-color: #fff;

	@media screen and(min-width: $row-width) {
		margin: 5rem auto 0;
	}

	> .featured {
		display: flex;
		flex-flow: row wrap;

		margin-bottom: 4rem;

		> .slide-nav,
		> .slideshow-wrap,
		> .slide-info {
			flex-grow: 1;
		}

		> .slide-nav,
		> .slide-info {
			display: flex;
			flex-basis: 20rem;
			align-items: center;
		}

		> .slide-nav {

			@media screen and (max-width: $row-width) {
				display: none;
			}

			padding: 1rem;

			> ul {
				width: 100%;

				> li {

					color: $color-gray;

					border-bottom: 1px solid $color-light-gray;

					&[data-active="true"] {
						color: $color-red;
					}

					&:first-of-type {
						border-top: 1px solid $color-light-gray;
					}

					&:hover {
						a {
							color: $color-red;
						}
					}

					> a {
						display: block;

						padding: 3rem;
					}

				}
			}
		}

		> .slideshow-wrap {
			flex-basis: 40rem;
			@media screen and (max-width: $row-width) {
				flex-basis: 25rem;
			}
		}

		 > .slide-info {
		 	padding: 1rem;
			> .container {

				@media screen and (max-width: $row-width) {
					width: 100%;
					text-align: center;
				}

			 	> .info-wrapper {
			 		@media screen and (min-width: $row-width) {
			 			padding: 3rem 0;

			 			border-top: 1px solid $color-light-gray;
			 			border-bottom: 1px solid $color-light-gray;

			 			> h3,
			 			> p {
			 				margin-bottom: 1.5rem;
			 			}
			 		}

			 		@media screen and (max-width: $row-width) {

						> h3,
						> p {
							margin-bottom: 0.7rem;
						}

			 			> h3 {
			 				font-size: 1.4rem;
			 			}
			 		}


					> .more {
						color: $color-red;
						font-weight: bold;
						text-transform: uppercase;
					}
			 	}
			}
		}
	}

	> section {
		@media screen and (min-width: $row-width) {
			padding: 0 1rem;

			> .flex-wrapper {
				margin: 0 -1rem;
			}
		}

		> .flex-wrapper {

			> .flex-row {

				width: 100%;
				padding: 3rem 0 1rem;

				> article {
					flex-basis: 21rem;
					flex-grow: 1;

					max-width: 21rem;

					margin: 0 1rem;
					margin-bottom: 3rem;

					> a {
						> .img {
							@include aspect-ratio(1,1);

							// background-color: $color-light-gray;
						}
					}
				}
			}
		}
	}
}