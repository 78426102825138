gecko-slideshow gecko-slide {
	$transition-speed: 1s;

	display: flex;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	transition: opacity #{ $transition-speed } ease-in-out;
	opacity: 0;

	&[data-state="in"] {
		z-index: 2;
		opacity: 1;
	}

	> .container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);

		> h1 {
			font-size: 4.5rem;
		}

		> p {
			margin-bottom: 2rem;
			font-size: 1.125rem;
		}

		h1,
		p,
		.button {
			color: #fff;
			letter-spacing: 0.1rem;
		}

		h1,
		p {
			padding: 1rem 0;
		}
	}
}
