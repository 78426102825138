$color-red: #d74b2f;

$color-blue: #32588F;
$color-light-blue: #e6ebf2;

$color-gray: #999999;
$color-light-gray: #e6ebf2;
$color-dark-gray: #666666;
$color-gray-rock: #dadfe6;
$color-notice-yellow: #ffffb1;

$color-trans-white: rgba(255, 255, 255, 0.9);