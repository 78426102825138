.flex-wrapper {
	> .flex-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		> article {
			max-width: 100%;

			> a {
				text-decoration: none;
				color: #000;

				> h4,
				> .img,
				> p {
					margin-bottom: 1rem;
				}

				> h4 {
					font-size: 1.2rem;
				}
			}
		}
	}
}