body > .newsletter {
	padding: 1.125rem 0;
	text-align: center;
	background-color: #e6ebf2;

	display: flex;
	justify-content: center;

	> .gform_wrapper {

		&.gform_validation_error {
			> form {
				display: flex;
				flex-flow: column wrap;
				.gform_body {
					padding: 1rem
				}
				.validation_error {
					margin-bottom: 1rem;

					color: $color-red;
				}

				.validation_message {
					padding-left: 0.5rem;
					align-self: center;
				}
			}
		}
		> form {
			display: flex;
			padding: 0 1rem;

			@media screen and (max-width: $row-width) {
				flex-flow: column wrap;
			}

			> .gform_body {

				@media screen and (max-width: $row-width) {
					margin: 0 1rem;
				}
				> .gform_fields {

					> li {
						display: flex;
						justify-content: space-around;
						flex-flow: row wrap;

						&.gform_validation_container {
							display: none;
						}

						&.gfield_error {
							> .validation_message {
								color: $color-red;
							}

							> .ginput_container {
								input {
									border: 2px solid $color-red;
									background-color: rgba(251, 171, 156, 0.5);
								}
							}
						}
					}

					input[type="email"] {
						width: 100%;

						padding: 1rem;
						background-color: #fff;
					}

					label {
						align-self: center;

						color: $color-dark-gray;
						font-size: 1.2rem;
						font-weight: bold;

						padding: 0 1rem;

						> .gfield_required {
							display: none;
						}
					}
					@media screen and (max-width: $row-width) {
						label,
						input {
							margin-bottom: 1rem
						}
					}
				}
			}

			> .gform_footer {
				input[type="submit"] {
					margin-left: 0.5rem;
					height: 100%;
				}
			}
		}
	}
}
