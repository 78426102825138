body.post-type-archive-exhibition {
	> main {
		> .wysiwyg {
			> .content {
				> .primary {
					> .timeline {
						> section {
							position: relative;

							&::before {
								position: absolute;
								top: 0;
								left: 0;

								height: 100%;
								width: 5rem;

								border-right: 1px solid $color-light-gray;

								content: "";

								z-index: -1;

								@media screen and (min-width: $row-width) {
									width: 50%;
								}

								@media screen and (max-width: $small-width) {
									display: none;
								}
							}

							> .no-results {
								width: 100%;

								text-align: center;

								font-size: 1.5rem;
							}

							> .year {
								position: relative;

								display: block;

								padding: 1rem;

								color: #fff;
								font-size: 1.5rem;
								text-align: center;

								background-color: $color-dark-gray;

								@media screen and (min-width: $row-width) {
									transform: translateY(1rem);
								}
							}

							> .exhibit {
								display: flex;
								flex-flow: row wrap;

								position: relative;

								justify-content: space-between;

								min-height: 15rem;
								margin-bottom: 2rem;

								&:hover {
									.dot {
										background-color: $color-red;
									}
								}

								@media screen and (min-width: 768px) and (max-width: $row-width) {
									padding: 1rem 1rem 1rem 10rem;
									justify-content: space-around;
								}

								@media screen and (max-width: 767px) {
									justify-content: center;
									margin: 4rem 0;
									padding: 0.5rem;
								}

								&.alt {
									@media screen and (min-width: $row-width) {
										justify-content: flex-end;

										> .bubble {
											left: 0;
											margin-left: 4rem;

											&::before {
												right: -36px;

												transform: rotate(180deg) translateY(50%);
											}

											&.is-hidden {
												transform: translateX(5%);
											}
										}

										> .info {
											align-items: flex-start;
											&.is-hidden {
												transform: translateX(-5%);
											}
										}
									}
								}

								&:not(.alt) {
									@media screen and (min-width: $row-width) {
										> .bubble {
											right: 0;
											margin-right: 4rem;

											&::before {
												left: -36px;
											}

											&.is-hidden {
												transform: translateX(-5%);
											}
										}

										> .info {
											align-items: flex-end;
											&.is-hidden {
												transform: translateX(5%);
											}
										}
									}
								}

								&:last-of-type {
									margin-bottom: 0;
								}

								> .dot {
									@include material-shadow(2);
									position: absolute;
									top: calc(50% - 35px);
									left: calc(50% - 35px);

									transform: scale(1);

									width: 70px;
									height: 70px;

									border-radius: 50%;

									border: 5px solid #fff;

									background-color: $color-light-gray;

									visibility: visible;
									opacity: 1;

									transition: all 1s ease;

									@media screen and (max-width: $row-width) {
										left: calc(5rem - 35px);
									}

									@media screen and (max-width: 767px) {
										display: none;
									}

									&.is-hidden {
										opacity: 0;
										transform: scale(0);
										visibility: hidden;
									}

									&::before {
										position: absolute;
										top: calc(50% - 0.75rem);
										left: calc(50% - 0.75rem);

										transform: trasnlate(-50%, -50%);

										font-family: fontawesome;
										color: #fff;
										content: "\f03e";

										font-size: 1.5rem;
									}

								}

								> .info {
									display: flex;
									flex-flow: column wrap;
									justify-content: center;

									transform: translateX(0);
									visibility: visible;
									opacity: 1;

									transition: all 1s ease;

									@media screen and (min-width: $row-width) {
										flex-basis: 38rem;
									}

									@media screen and (max-width: $small-width) {
										align-items: center;
									}

									&.is-hidden {
										opacity: 0;
										visibility: hidden;
									}

									> .date {
										margin-bottom: 0.7rem;

										font-size: 1.6rem;
										font-family: $bold-font-family;
										font-weight: bold;
										color: $color-dark-gray;
									}

									> .artist {
										font-size: 1.3rem;

										@media screen and (max-width: $small-width) {
											padding: 1.5rem 0;
										}
									}
								}

								> .bubble {
									@include material-shadow(2);

									display: flex;
									flex-flow: column nowrap;

									max-width: 32rem;

									margin: 1rem 0;

									background-color: #fff;
									border: 2px solid $color-light-gray;
									padding: 2rem;


									transform: translateX(0);
									visibility: visible;
									opacity: 1;

									transition: all 1s ease;

									@media screen and (min-width: $row-width) {
										position: absolute;
										top: -5rem;

										width: 100%;

										&::before {
											position: absolute;
											top: 55%;

											transform: translateY(-50%);

											content: '';

											height: 0;
											width: 0;
										}

									}

									&::before {
										content: "";

										background-image: url('../images/arrow.png');
										background-position: center;
										background-repeat: no-repeat;
										background-size: contain;

										height: 50px;
										width: 50px;

										@media screen and (max-width: $row-width) {
											display: none;
										}
									}

									&.is-hidden {
										opacity: 0;
										visibility: hidden;
									}


									> .img {
										flex-basis: 15rem;

										min-width: 15rem;

										background-size: contain;
									}

									> span {
										display: block;

										text-align: center;
										margin-top: 1rem;
										> a {
											padding: 1rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}