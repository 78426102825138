body.page-template-default {
	> main {
		.content  {
			margin: 3rem 0;
			> .img {
				@include aspect-ratio(2,1);

				margin-bottom: 2rem;
			}
		}
	}
}