GeckoRevealer {
	display: flex;
	justify-content: center;

	height: 4rem;
	width: 100%;

	position: absolute;
	bottom: 0;
	left: 0;

	background-image: linear-gradient(to bottom, rgba(white, 0.4) 10%, white 80%);

	&[data-active="true"] {
		#trigger {
			&::after {
				content: "\f078";
			}
		}
	}

	#trigger {
		display: block;
		position: relative;

		margin-top: 3rem;

		color: $color-red;

		&::after {
			position: absolute;
			top: 0;
			right: -1.2rem;

			height: 1rem;
			width: 1rem;

			content: "\f077";
			font-family: fontawesome;
			font-size: 1rem;
		}

		&:hover {
			cursor: pointer;
		}
	}
}