body.single-artist {
	> main {
		> .clamped-width {
			> article {
				margin-bottom: 3rem;
				> .primary {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;

					margin: 3rem 0;

					> .meta {
						flex-basis: 25rem;
						flex-grow: 1;

						> .img {
							padding-bottom: 50%;
							max-height: 30rem;

							background-size: contain;
							background-position: center top;

							@media screen and (max-width: $row-width) {
								margin-bottom: 2rem;
							}

							@media screen and (max-width: $small-width) {
								height: 15rem;
							}
						}
					}

					> .content {
						position: relative;

						display: block;
						// flex-flow: column wrap;
						flex-basis: 30rem;
						flex-grow: 1;

						transition: height 1s ease-in-out;
						overflow: hidden;

						@media screen and (min-width: $small-width) {
							padding: 0 2rem;
						}

						> p {
							margin-top: 0;
						}

						> ul {
							margin: 0 0 0.5rem;
							> li {
								margin-bottom: 1rem;
								list-style-type: none;
								// font-size: 1.2rem;

								line-height: 1.3;
								> span {
									font-weight: bold;
									font-family: $bold-font-family;
									color: $color-dark-gray;
									margin-right: 0.4rem;
								}
							}
						}
					}
				}
			}

			> section.flex-wrapper {

				> .flex-row {
					margin: 3rem -1rem;

					> article {
						flex-basis: 16rem;
						flex-grow: 1;

						margin: 0 1rem 3rem;
						> a {
							> .img {
								@include aspect-ratio(1,1);

								// background-color: $color-light-gray;

								margin-bottom: 1rem;
							}
						}
					}

					> .dummy {
						flex-basis: 16rem;
						margin: 0 1rem 3rem;

						flex-grow: 1;
						flex-shrink: 0;
					}
				}
			}
		}
	}
}