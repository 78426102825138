body > footer {
	display: flex;
	justify-content: center;
	flex-flow: column nowrap;
	margin: 0 auto;
	padding: 3.25rem 2rem;

	font-size: 1.125rem;
	color: #fff;

	background-color: $color-dark-gray;

	@media screen and (min-width: $row-width) {
		justify-content: space-between;
		flex-flow: row wrap;
	}

	> .copyright {
		text-align: center;
		margin-bottom: 1rem;
	}

	nav {
		text-align: center;
		ul > li {
			display: inline-block;
			border-left: solid 1px #fff;

			&:first-child {
				border-style: none;
			}

			&:last-child {

				a {
					margin-right: 0;
				}
			}

			&:hover {
				> a {
					border-bottom: 1px solid #fff;
				}
			}

			// &.contact a::before,
			&.menu-item-5171 a::before {
				content: "\f0e0";
			}

			// &.phone a::before
			&.menu-item-5172 a::before {
				content: "\f095";
			}

			> a {
				display: block;
				margin: 0 1rem;
				color: #fff;

				&::before {
					margin-right: 0.5rem;
					font-family: fontawesome;
					color: #999;
				}
			}
		}
	}
}