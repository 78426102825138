.woocommerce-pagination {
	display: flex;
	justify-content: center;


	> ul.page-numbers {
		display: flex;
		justify-content: center;

		padding: 2rem 2rem 5rem 2rem;

		> li {
			display: flex;
			justify-content: center;

			list-style-type: none;

			margin: 0 0.5rem;
			min-width: 3rem;

			color: $color-red;

			&:hover {
				background-color: $color-red;
				color: #fff;
			}

			> span,
			> a {
				padding: 1.2rem 1.5rem;

				color: inherit;
				border: 1px solid $color-red;

				&.prev,
				&.next {
					border: none;
				}

				&.current {
					background-color: $color-red;
					color: #fff;
				}
			}
		}
	}
}