/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */

body {
	font-family: $body-font-family;
	font-weight: 300;
	font-size: 1rem;

	background-color: #fff;
}
h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2.3rem;
}

h3 {
	font-size: 1.9rem;
}

h4 {
	font-size: 1.4rem;
}

h1,
h2,
h3,
h4 {
	font-weight: 700;
	color: $color-dark-gray;
	line-height: 1.5;
}

a,
input[type="submit"] {
	cursor: pointer;
	transition: color 0.2s linear;
}

p {
	line-height: 2;
}

hr {
	display: block;
	margin: 2rem 0;

	border: 0;
	border-bottom: 1px solid $color-red;
}

.button {
	padding: 1rem;

	background-color: $color-red;

	text-transform: uppercase;
	font-weight: bold;
	color: #fff;
}

.img {
	max-width: 100%;

	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.is-hidden {
	visibility: hidden;
}

.dummy {
	flex-grow: 1;

	height: 0;
	width: 0;

	margin-top: 0;
	margin-bottom: 0;

	visibility: hidden;
}

main {
	@media screen and (max-width: $row-width) {
		padding: 0 1rem;
	}
}

.flex-row {
	article {
		h4 {
			transition: color 0.3s linear;
		}

		h4,
		p {
			text-align: center;
		}

		&:hover {
			h4 {
				color: $color-red;
			}
		}
	}
}