body.single-exhibition {
	> main {
		> .clamped-width {
			> .wysiwyg {
				> .title {
					flex-flow: column;
					padding-bottom: 1.5rem;
					> h1 {
						margin-top: 2rem;
					}

					> .subtitle {
						display: block;

						margin: 1rem 0 0;
						font-size: 1.4rem;
					}
				}

				> .primary {
					> section {
						margin: 2rem 0;
						> .no-results {
							text-align: center;
							padding: 3rem;

						}

						> .flex-wrapper {
							@media screen and (min-width: $row-width) {
								margin-top: 3rem;
								> .flex-row {
									margin: 0 -1rem;
									> article {
										max-width: 16rem;
									}
								}
							}

							> .flex-row {
								> article {
									flex-basis: 16rem;
									flex-grow: 1;

									margin: 0 1rem 2rem;
									a {
										> h4 {
											text-align: center;
										}

										> .img {
											@include aspect-ratio(1,1);

											margin-bottom: 1rem;
										}
									}
								}

								> .dummy {
									flex-basis: 16rem;
									margin: 0 1rem 2rem;

									max-width: 16rem;
								}
							}
						}
					}
				}
			}
		}
	}
}