aside.modal-cart {
	@include material-shadow(2);

	position: fixed;
	top: 3.5rem;
	right: -100%;

	width: 350px;
	height: auto;

	padding: 1rem;

	background-color: #fff;

	opacity: 0;

	transition: all 0.5s ease;
	z-index: 40;

	&.active {
		right: 2rem;

		display: block;

		opacity: 1;
		z-index: 40;
	}

	#cart-preview {
		position: relative;

		&::after {
			position: absolute;

			content: "";

			top: calc(-1rem - 10px);
			right: -1rem;

			height: 0;
			width: 0;

			border-style: solid;
			border-width: 0 10px 10px 10px;
			border-color: transparent transparent #fff transparent;

		}

		h4 {
			padding: 0 0.5rem;
		}

		ul.cart-items {

			&:not(.empty) {
				padding-bottom: 1rem;
				border-bottom: 1px solid $color-gray;
			}

			li {
				display: flex;
				justify-content: space-between;

				padding: 1rem 0.5rem;

				.item-title {
					flex-basis: 10rem;
					display: inline-block;

					color: $color-red;
					text-decoration: underline;
				}

				.amount {
					text-align: right;
					flex-basis: 4rem;
				}
				> a,
				> span {
					flex-grow: 1;
				}
			}
		}

		> .cart-total {
			display: flex;
			justify-content: space-between;

			padding: 1rem;
			> span {
				align-self: flex-start;
				font-weight: 700;
			}

			> .amount {
				align-self: flex-end;
			}
		}

		.bottom-button {
			display: flex;
			justify-content: space-between;

			border-radius: 5px;

			a.cart-button {
				margin-top: 1rem;

				color: #fff;
				font-weight: 700;

				&::after {
					content: '\f105';
					font-family: fontawesome;

					margin-left: 0.5rem;

					color: inherit;
				}
			}
		}
	}
}