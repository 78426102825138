body.single-product {
	> main {
		> .wysiwyg {
			> h1 {
				border-bottom: 1px solid $color-light-gray;
				padding: 3rem 0;
			}

			> .content {

				> .woocommerce-message {
					margin-top: 2rem;
				}

				> .product {
					position: relative;

					display: flex;
					flex-flow: row wrap;

					margin-top: 3.5rem;
					margin-bottom: 5rem;

					> .onsale {
						position: absolute;
						top: -1.5rem;
						left: 1rem;

						padding: 1.2rem;

						border-radius: 10%;

						color: #fff;
						background-color: $color-red;
					}

					> .images {
						flex-basis: 15rem;
						flex-grow: 1;
						flex-shrink: 0;

						> a {
							> .img {
								@include aspect-ratio(2,1);

								// height: 100%;
								width: 100%;

								background-size: contain;
								background-position: center top;

								margin-bottom: 1rem;
							}
						}

						> .enlarge_button {
							position: relative;

							padding-left: 1.5rem;

							@media screen and (max-width: $row-width) {
								display: block;
								margin: 1rem auto 2rem;

								width: 10rem;
							}

							&::before {
								content: "\f00e";

								position: absolute;
								top: 0;
								left: 0;

								font-size: 1.2rem;
								font-family: fontawesome;
								color: inherit;
							}

							&:hover {
								cursor: pointer;
							}
						}
					}

					> .summary {
						align-self: center;
						flex-basis: 32rem;

						padding: 2rem;

						@media screen and (max-width: $row-width) {
							margin: 2rem auto 0;
						}

						> .in-stock,
						> .product_meta {
							display: none;
						}

						> .out-of-stock {
							display: block;

							text-align: center;
						}

						> ul.sidebar-info {
							padding-bottom: 3rem;
							margin-left: 0;

							border-bottom: 1px solid $color-light-gray;

							@media screen and (min-width: $row-width) {
								padding-right: 5rem;
							}

							> li {
								display: flex;
								flex-flow: row nowrap;
								justify-content: space-between;

								padding: 1rem 0;

								font-size: 1.2rem;
								list-style-type: none;
								text-align: right;

								&.description {
									text-align: left;
									line-height: 1.2;
								}

								> span {
									padding-right: 1rem;

									font-family: $bold-font-family;
									font-weight: bold;
									color: $color-dark-gray;
								}
							}
						}

						> .inquire {
							display: block;

							padding: 2rem 1rem;

							border-bottom: 1px solid $color-light-gray;
							> a {
								position: relative;

								padding: 2rem 1rem;

								font-weight: bold;
								font-family: $bold-font-family;
								text-decoration: none;
								text-transform: uppercase;
								font-size: 1rem;

								&::before {
									position: absolute;
									left: -1rem;
									top: 40%;

									font-family: fontawesome;
									color: $color-gray;
									content: "\f075";
								}
							}
						}

						> ul.sidebar-social {
							display: flex;
							justify-content: space-between;

							margin-left: 0;
							margin-bottom: 2rem;

							border-bottom: 1px solid $color-light-gray;

							> li {
								flex-basis: 2rem;

								padding: 2rem 0;
								list-style-type: none;

								> a {
									position: relative;
									text-decoration: none;

									&.share,
									&.fb,
									&.tw,
									&.pn,
									&.em {
										&::before {
											position: absolute;

											display: inline-block;
											font-family: fontawesome;
										}
									}

									&.share {
										padding: 0 1rem 0 2rem;

										color: $color-gray;
										text-transform: uppercase;
										font-weight: 700;
										font-family: $bold-font-family;

										&::before {
											left: 0rem;
											top: 20%;

											color: inherit;
											content: "\f1e0";
										}
									}

									&.fb::before {
										color: #3b5998;
										content: "\f230";
									}

									&.tw::before {
										color: #00aced;
										content: "\f081";
									}

									&.pn::before {
										color: #cb2027;
										content: "\f0d3";
									}

									&.em::before {
										color: #d74b2f;
										content: "\f199";
									}
								}
							}
						}
						> .cart {
							.variations,
							.quantity {
								display: none;
							}

							button[type="submit"]{
								position: relative;
								padding: 1rem 2rem 1rem 3rem;

								&::before {
									position: absolute;

									left: 1.5rem;

									font-family: fontawesome;
									color: #ea9280;
									content: "\f07a";
								}
							}
						}

						> .product_meta {
							margin-top: 1rem;
						}
					}
				}

				> section {
					> .flex-wrapper {
						@media screen and (min-width: $row-width) {
							margin: 0 -1rem;
						}

						> .flex-row {
							padding: 3rem 0;

							> article {
								flex-basis: 16rem;
								flex-grow: 1;

								margin: 0 1rem 3rem;

								a {
									> .img {
										@include aspect-ratio(1,1);

										// background-color: $color-light-gray;

										margin-bottom: 1rem;
									}
								}
							}
							> .dummy {
								flex-basis: 16rem;
								margin: 0 1rem 3rem;
							}
						}
					}
				}
			}
		}
	}
}