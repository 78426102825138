body.woocommerce-cart {
	.content {
		> .woocommerce {
			margin-bottom: 3rem;

			> form {
				> table {
					border-collapse: separate;
					border-spacing: 0 1rem;
					> thead {
						> tr {
							> th {
								font-size: 1.3rem;
								@media screen and (min-width: $small-width) {
									padding: 1rem;
								}
							}
						}
					}

					> tbody {
						> tr {
							background-color: $color-light-gray;
							margin-bottom: 1rem;

							> td {
								@media screen and (min-width: $small-width) {
									padding: 1rem;
								}
							}
						}
					}
				}
			}

			> .cart-collaterals {
				> .cart_totals {
					> .shop_table {
						> tbody {
							> tr {
								display: flex;
								justify-content: flex-start;
								align-items: center;
								flex-grow: 1;

								&.shipping {
									display: none;
								}

								> th {
									font-size: 1.2rem;
								}

								> td {
									width: 100%;
									max-width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
	.shop_table {

		tbody .cart_item {

			.remove {
				display: flex;
				justify-content: center;

				margin: 0 auto;
				align-items: center;

				height: 3rem;
				width: 3rem;

				text-align: center;
				font-size: 3rem;
				line-height: 1em;

				border-radius: 3px;
			}

			.product-thumbnail {

				a {
					display: inline-block;
					padding: 0.25rem;

					border-radius: 3px;
					background-color: #FFF;

					img {
						display: block;
						margin: 0 auto;
					}
				}
			}
		}

		tbody .actions {
			padding: 1rem;

			.coupon {
				display: inline-flex;
				align-items: center;

				label {
					display: none;
				}

				input[type="text"] {
					margin: 0 0.5rem;
					padding: 0.8rem;

					background-color: #fff;
				}
			}

			input[name="update_cart"] {
				float: right;
			}
		}
	}

	.cart-collaterals {
		padding: 0.5rem 0 0;

		h2 {
			margin-bottom: 1rem;
		}

		.shop_table {
			border: 0;

			background-color: $color-light-gray;

			tbody tr {
				padding: 1rem;

				th {
					padding: 0.5rem;
					text-align: right;
				}

				td {
					padding: 1rem;
					width: 1rem;

					ul,
					ol {
						margin: 0;
					}

					p {
						margin: 0.5rem;

						> input[type="text"] {
							background-color: #fff;
							padding: 0 0.2rem;
						}
					}
				}
			}
		}

		.wc-proceed-to-checkout {
			margin-top: 1rem;
			text-align: right;
		}
	}
}