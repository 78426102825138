main {
	> .wysiwyg {
		> .content {
			form {
				> .gform_body {
					> ul {
						> li {
							&.gform_validation_container {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}