ul.pagination {
	display: flex;
	justify-content: center;

	padding: 2rem 2rem 5rem 2rem;

	@media screen and (max-width: $small-width) {
		display: block;
		align-items: center;
	}

	> li {
		display: flex;
		justify-content: center;

		color: $color-red;
		text-decoration: none;
		list-style-type: none;

		&:hover {
			background-color: $color-red;
			color: #fff;
		}

		&.prev,
		&.next {
			align-self: center;

			padding: 0 1rem;

			> a,
			> span {
				text-transform: uppercase;
				text-decoration: none;

				color: inherit;
			}

			&::before,
			&::after {
				font-family: fontawesome;
				color: inherit;
			}
		}

		&.prev {
			&::before {
				padding-right: 0.5rem;
				content: '\f104';
			}
		}

		&.next {
			&::after {
				padding-left: 0.5rem;
				content: '\f105';
			}
		}

		&.page-no {
			margin: 0 0.5rem;
			min-width: 3rem;

			> a,
			> span {
				padding: 1.2rem 1.5rem;
				text-decoration: none;

				color: inherit;
			}
			> .page-numbers {
				border: 1px solid $color-red;

				&.current {
					background-color: $color-red;

					color: #fff;
				}

				&.prev,
				&.next {
					border: none;
				}

				@media screen and (max-width: $small-width) {
					display: none;

					&.prev,
					&.next,
					&.current {
						display: block;
					}
				}
			}
		}
	}
}

.screen-reader-text {
	display: none;
}

.nav-links {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	margin: 3rem 0;

	.title {
		display: block;
		padding: 0 0.5rem 0.5rem;
		width: 100%;

		text-align: center;
	}

	a,
	span {
		margin: 0 0.5rem;

		border: 1px solid $color-red;
		padding: 1rem;
		color: $color-red;
		&.prev,
		&.next {
			border: none;
			color: $color-red;
		}

		&.current {
			background-color: $color-red;
			color: #fff;
		}

		@media screen and (max-width: $small-width) {
			display: none;

			&.prev,
			&.next,
			&.current {
				display: block;
			}
		}
	}
}