body.woocommerce-checkout {
	> main {
		> .wysiwyg {
			> .content {
				> .woocommerce {
					> .woocommerce-thankyou-order-details {

						margin: 0;
						padding: 1rem 0;
						> li {
							padding: 0.5rem;
							list-style-type: none;
						}
					}

					> .order_details,
					> .customer_details {
						background-color: $color-light-gray;
						margin-bottom: 3rem;

						> tbody {
							> tr {
								> td {
									padding: 1rem;
								}
							}
						}
					}
				}

				address {
					margin-bottom: 3rem;
					padding: 1rem;

					background-color: $color-light-gray;
					line-height: 1.3;
				}
			}
		}
	}
}