// Add this class or pseudoclass to any content generated by WordPress.
// Additionally, it can be used when you want to ensure consistent styling.

// Edit + remove styles here freely.

%wysiwyg, .wysiwyg {
	h1 {
		font-size: 3rem;

		@media screen and (max-width: $row-width) {
			font-size: 2rem;
		}
	}

	h2 {
		font-size: 2.3rem;

		@media screen and (max-width: $row-width) {
			font-size: 1.3rem;
		}
	}

	h3 {
		font-size: 1.9rem;

		@media screen and (max-width: $row-width) {
			font-size: 1.3rem;
		}
	}

	h4 {
		font-size: 1.4rem;
	}

	h1,
	h2,
	h3,
	h4 {
		font-weight: 700;
		font-family: $bold-font-family;
		color: $color-dark-gray;
		line-height: 1.5;
	}

	p {
		line-height: 1.3;
		margin: 1rem 0;
	}

	b, strong {
		font-weight: bold;
	}

	i, em {
		font-style: italic;
	}

	ul {
		list-style: disc;
		margin-left: 1rem;
	}

	ol {
		counter-reset: ol;

		> li {
			counter-increment: ol;

			&::before {
				content: counter(ol);
				margin-right: 0.5rem;
			}
		}
	}

	a {
		color: $color-red;
	}

	button, a.button, input[type="button"] {
		display: inline-block;

		padding: 1rem;

		color: #fff;
		text-decoration: none;
	}

	input[type="text"], input[type="tel"], input[type="email"], textarea {
		border: 1px solid black;
	}
}