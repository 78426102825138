.fancy {
	position: relative;

	&::after {
		position: absolute;
		top: 50%;
		right: 0;

		display: block;

		height: 1px;
		width: 100%;

		content: "";

		background-color: $color-light-blue;

		@media screen and (max-width: $row-width) {
			display: none;
		}

	}

	> a {
		position: relative;
		background-color: #fff;

		float: right;
		padding: 0 0 0 2rem;

		color: $color-red;
		font-size: 1.2rem;
		text-transform: uppercase;
		text-decoration: none;

		transform: translateY(50%);

		z-index: 1;
	}

	> span {
		position: relative;
		background-color: #fff;

		z-index: 1;

		@media screen and (min-width: $row-width) {
			padding-right: 2rem;
		}
	}
}