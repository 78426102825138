@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Oswald:300,400,700|Source+Sans+Pro:300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 *
 * Improved by Gecko Designs to reset ALL the things!
 */
html, body, div, span, applet, object, iframe,
button, input, select, textarea,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  background: none;
  vertical-align: baseline; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a {
  text-decoration: none;
  color: inherit; }

h1, h2, h3, h4, h5, h6 {
  text-decoration: none; }

main > .clamped-width {
  max-width: 87.3rem;
  margin: 0 auto; }

/* Full-width mixin (1.0.0)
 * Creates a full-width section
 * Side-effect: element must be position relative
 */
/* Grid Box (1.0.0)
 * Creates a variable-count and variable-gutter grid system.
 * Apply this class to all children and ensure that they are the only members of the grid container.
 * This system is much more flexible than Foundation, it should be used for grids instead of columns.
 */
/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */
/* Touch Nav Detection (1.1.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
/* Base Styles
 * The styles that go here should be site-global and not specific to any individual components.
 */
body {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  background-color: #fff; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2.3rem; }

h3 {
  font-size: 1.9rem; }

h4 {
  font-size: 1.4rem; }

h1,
h2,
h3,
h4 {
  font-weight: 700;
  color: #666666;
  line-height: 1.5; }

a,
input[type="submit"] {
  cursor: pointer;
  transition: color 0.2s linear; }

p {
  line-height: 2; }

hr {
  display: block;
  margin: 2rem 0;
  border: 0;
  border-bottom: 1px solid #d74b2f; }

.button {
  padding: 1rem;
  background-color: #d74b2f;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff; }

.img {
  max-width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.is-hidden {
  visibility: hidden; }

.dummy {
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 0;
  width: 0;
  margin-top: 0;
  margin-bottom: 0;
  visibility: hidden; }

@media screen and (max-width: 87.3rem) {
  main {
    padding: 0 1rem; } }

.flex-row article h4 {
  transition: color 0.3s linear; }

.flex-row article h4,
.flex-row article p {
  text-align: center; }

.flex-row article:hover h4 {
  color: #d74b2f; }

body.woocommerce-account.woocommerce-page.logged-in.woocommerce-orders main > .wysiwyg > .content .woocommerce .woocommerce-MyAccount-content .woocommerce-MyAccount-orders {
  margin: 2rem 0; }
  body.woocommerce-account.woocommerce-page.logged-in.woocommerce-orders main > .wysiwyg > .content .woocommerce .woocommerce-MyAccount-content .woocommerce-MyAccount-orders > tbody > tr > td {
    text-align: center; }

body.woocommerce-account.woocommerce-page.logged-in.woocommerce-edit-account > main > .wysiwyg > .content > .woocommerce .woocommerce-error {
  width: 100%;
  margin: 0 0 2rem; }

body.woocommerce-account.woocommerce-page.logged-in.woocommerce-edit-account > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content {
  background-color: #e6ebf2;
  padding: 1rem; }
  body.woocommerce-account.woocommerce-page.logged-in.woocommerce-edit-account > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content > form input[type="password"],
  body.woocommerce-account.woocommerce-page.logged-in.woocommerce-edit-account > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content > form input[type="email"],
  body.woocommerce-account.woocommerce-page.logged-in.woocommerce-edit-account > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content > form input[type="text"] {
    display: block;
    min-width: 15rem;
    padding: 0.3rem; }

body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap; }
  @media screen and (min-width: 87.3rem) {
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce {
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      margin: 0 -2rem; }
      body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-navigation {
        padding: 1rem; } }
  @media screen and (min-width: 47.93rem) {
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content {
      padding: 0 1rem; }
      body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content table {
        border-spacing: 1rem;
        border-collapse: separate; } }
  body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-navigation {
    -ms-flex-preferred-size: 15rem;
    flex-basis: 15rem;
    background-color: #e6ebf2;
    padding: 1rem; }
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-navigation > ul {
      margin: 0; }
      body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-navigation > ul li {
        list-style-type: none;
        padding: 0.5rem; }
        body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-navigation > ul li.is-active {
          background-color: #d74b2f; }
          body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-navigation > ul li.is-active a {
            color: #fff; }
  body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content {
    -ms-flex-positive: 1;
    flex-grow: 1; }
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content > .woocommerce-Message {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center; }
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content address {
      line-height: 1.5; }
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content form input[type="text"],
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content form input[type="email"],
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content form input[type="password"],
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content form text-area {
      background-color: #fff;
      border: 1px solid #999999; }
    body.woocommerce-account.woocommerce-page.logged-in > main > .wysiwyg > .content > .woocommerce > .woocommerce-MyAccount-content > .woocommerce-Addresses > .woocommerce-Address {
      margin-bottom: 1rem; }

body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce {
  background-color: #fff; }
  body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce > h2 {
    max-width: 30rem;
    margin: 0 auto 1rem; }
  body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce > .col2-set {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
    body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce > .col2-set h2 {
      max-width: 30rem;
      margin: 0 auto 1rem; }
  body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form {
    max-width: 30rem;
    margin: 0 auto;
    padding: 1rem 2rem;
    background-color: #e6ebf2; }
    body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap; }
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p > input[type="text"],
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p > input[type="password"],
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p > label {
        display: block;
        width: 100%; }
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p > input[type="text"],
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p > input[type="password"] {
        padding: 0.5rem 1rem; }
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p > input[type="submit"] {
        margin: 1rem 0;
        width: 15rem;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center; }
      body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form > p.lost_password > a {
        text-align: center; }
    body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form input[type="text"],
    body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form input[type="email"],
    body.woocommerce-account.woocommerce-page:not(.logged-in) > main > .wysiwyg > .content > .woocommerce form input[type="password"] {
      border: 1px solid black;
      background-color: #fff; }

body.woocommerce-cart .content > .woocommerce {
  margin-bottom: 3rem; }
  body.woocommerce-cart .content > .woocommerce > form > table {
    border-collapse: separate;
    border-spacing: 0 1rem; }
    body.woocommerce-cart .content > .woocommerce > form > table > thead > tr > th {
      font-size: 1.3rem; }
      @media screen and (min-width: 47.93rem) {
        body.woocommerce-cart .content > .woocommerce > form > table > thead > tr > th {
          padding: 1rem; } }
    body.woocommerce-cart .content > .woocommerce > form > table > tbody > tr {
      background-color: #e6ebf2;
      margin-bottom: 1rem; }
      @media screen and (min-width: 47.93rem) {
        body.woocommerce-cart .content > .woocommerce > form > table > tbody > tr > td {
          padding: 1rem; } }
  body.woocommerce-cart .content > .woocommerce > .cart-collaterals > .cart_totals > .shop_table > tbody > tr {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    body.woocommerce-cart .content > .woocommerce > .cart-collaterals > .cart_totals > .shop_table > tbody > tr.shipping {
      display: none; }
    body.woocommerce-cart .content > .woocommerce > .cart-collaterals > .cart_totals > .shop_table > tbody > tr > th {
      font-size: 1.2rem; }
    body.woocommerce-cart .content > .woocommerce > .cart-collaterals > .cart_totals > .shop_table > tbody > tr > td {
      width: 100%;
      max-width: 100%; }

body.woocommerce-cart .shop_table tbody .cart_item .remove {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  -ms-flex-align: center;
  align-items: center;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 3rem;
  line-height: 1em;
  border-radius: 3px; }

body.woocommerce-cart .shop_table tbody .cart_item .product-thumbnail a {
  display: inline-block;
  padding: 0.25rem;
  border-radius: 3px;
  background-color: #FFF; }
  body.woocommerce-cart .shop_table tbody .cart_item .product-thumbnail a img {
    display: block;
    margin: 0 auto; }

body.woocommerce-cart .shop_table tbody .actions {
  padding: 1rem; }
  body.woocommerce-cart .shop_table tbody .actions .coupon {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center; }
    body.woocommerce-cart .shop_table tbody .actions .coupon label {
      display: none; }
    body.woocommerce-cart .shop_table tbody .actions .coupon input[type="text"] {
      margin: 0 0.5rem;
      padding: 0.8rem;
      background-color: #fff; }
  body.woocommerce-cart .shop_table tbody .actions input[name="update_cart"] {
    float: right; }

body.woocommerce-cart .cart-collaterals {
  padding: 0.5rem 0 0; }
  body.woocommerce-cart .cart-collaterals h2 {
    margin-bottom: 1rem; }
  body.woocommerce-cart .cart-collaterals .shop_table {
    border: 0;
    background-color: #e6ebf2; }
    body.woocommerce-cart .cart-collaterals .shop_table tbody tr {
      padding: 1rem; }
      body.woocommerce-cart .cart-collaterals .shop_table tbody tr th {
        padding: 0.5rem;
        text-align: right; }
      body.woocommerce-cart .cart-collaterals .shop_table tbody tr td {
        padding: 1rem;
        width: 1rem; }
        body.woocommerce-cart .cart-collaterals .shop_table tbody tr td ul,
        body.woocommerce-cart .cart-collaterals .shop_table tbody tr td ol {
          margin: 0; }
        body.woocommerce-cart .cart-collaterals .shop_table tbody tr td p {
          margin: 0.5rem; }
          body.woocommerce-cart .cart-collaterals .shop_table tbody tr td p > input[type="text"] {
            background-color: #fff;
            padding: 0 0.2rem; }
  body.woocommerce-cart .cart-collaterals .wc-proceed-to-checkout {
    margin-top: 1rem;
    text-align: right; }

body.woocommerce-checkout .woocommerce > form {
  background-color: #e6ebf2; }
  body.woocommerce-checkout .woocommerce > form > .woocommerce-error {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin: 0 0 2rem;
    background-color: rgba(215, 75, 47, 0.1);
    border: 1px solid #d74b2f; }
    body.woocommerce-checkout .woocommerce > form > .woocommerce-error > li {
      padding: 0.5rem;
      margin-left: 2rem; }
  body.woocommerce-checkout .woocommerce > form input[type="email"],
  body.woocommerce-checkout .woocommerce > form input[type="password"],
  body.woocommerce-checkout .woocommerce > form input[type="text"],
  body.woocommerce-checkout .woocommerce > form input[type="tel"],
  body.woocommerce-checkout .woocommerce > form textarea {
    background-color: #fff; }
  body.woocommerce-checkout .woocommerce > form.checkout {
    margin-top: 3rem;
    padding: 1rem; }

body.woocommerce-checkout .woocommerce > .woocommerce-info {
  margin: 0 0 1rem;
  text-align: center; }

body.woocommerce-checkout .woocommerce > .login {
  margin: 0 0 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 3px; }
  body.woocommerce-checkout .woocommerce > .login p {
    margin: 0 0 0.5rem; }
    body.woocommerce-checkout .woocommerce > .login p:last-of-type {
      margin: 0; }

body.woocommerce-checkout .woocommerce > form > .woocommerce-checkout-review-order {
  margin-bottom: 3rem; }
  body.woocommerce-checkout .woocommerce > form > .woocommerce-checkout-review-order > table > tbody {
    border-bottom: 1px solid #999999; }
  body.woocommerce-checkout .woocommerce > form > .woocommerce-checkout-review-order > table > tfoot > tr.shipping {
    display: none; }
  body.woocommerce-checkout .woocommerce > form > .woocommerce-checkout-review-order > table > tfoot > tr > td > .notice {
    padding: 0.7rem;
    width: 20rem;
    background-color: #ffffb1; }
    body.woocommerce-checkout .woocommerce > form > .woocommerce-checkout-review-order > table > tfoot > tr > td > .notice > .header {
      text-transform: uppercase; }

body.woocommerce-checkout .woocommerce > .checkout_coupon {
  display: -ms-flexbox;
  display: flex;
  padding: 1rem; }
  body.woocommerce-checkout .woocommerce > .checkout_coupon .form-row {
    display: -ms-flexbox;
    display: flex;
    margin: 0;
    float: none; }
  body.woocommerce-checkout .woocommerce > .checkout_coupon .clear {
    display: none; }
  body.woocommerce-checkout .woocommerce > .checkout_coupon .button {
    margin: 0; }

body.woocommerce-checkout .col2-set {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 2rem; }
  @media (max-width: 860px) {
    body.woocommerce-checkout .col2-set {
      -ms-flex-direction: column;
      flex-direction: column; } }
  body.woocommerce-checkout .col2-set > div {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 1rem;
    flex-basis: 1rem; }
    @media (max-width: 860px) {
      body.woocommerce-checkout .col2-set > div {
        -ms-flex-preferred-size: auto;
        flex-basis: auto; } }
  body.woocommerce-checkout .col2-set .col-1 {
    padding-right: 1rem; }
    @media (max-width: 860px) {
      body.woocommerce-checkout .col2-set .col-1 {
        margin-bottom: 1rem;
        padding-right: 0; } }
  body.woocommerce-checkout .col2-set .col-2 {
    padding-left: 1rem; }
    @media (max-width: 860px) {
      body.woocommerce-checkout .col2-set .col-2 {
        padding-left: 0; } }

body.woocommerce-checkout .form-row {
  margin: 0 0 0.5rem; }
  body.woocommerce-checkout .form-row.form-row-first, body.woocommerce-checkout .form-row.form-row-last {
    float: left;
    width: 50%; }
    @media (max-width: 540px) {
      body.woocommerce-checkout .form-row.form-row-first, body.woocommerce-checkout .form-row.form-row-last {
        float: none;
        width: 100%; } }
  body.woocommerce-checkout .form-row.form-row-first {
    padding-right: 0.5rem; }
    @media (max-width: 540px) {
      body.woocommerce-checkout .form-row.form-row-first {
        padding-right: 0; } }
  body.woocommerce-checkout .form-row.form-row-last {
    padding-left: 0.5rem; }
    @media (max-width: 540px) {
      body.woocommerce-checkout .form-row.form-row-last {
        padding-left: 0; } }
  body.woocommerce-checkout .form-row label {
    display: block;
    color: #666; }
  body.woocommerce-checkout .form-row textarea,
  body.woocommerce-checkout .form-row input[type="text"],
  body.woocommerce-checkout .form-row input[type="tel"],
  body.woocommerce-checkout .form-row input[type="password"],
  body.woocommerce-checkout .form-row input[type="email"] {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem; }
  body.woocommerce-checkout .form-row.woocommerce-invalid textarea,
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="text"],
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="tel"],
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="password"],
  body.woocommerce-checkout .form-row.woocommerce-invalid input[type="email"] {
    border-color: #d74b2f;
    background-color: rgba(215, 75, 47, 0.1); }

body.woocommerce-checkout .select2-container .select2-choice > .select2-chosen {
  padding: 0.5rem; }

body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods {
  margin: 0 0 1rem;
  padding: 1rem 0 0;
  border: 0; }
  body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li {
    display: block;
    margin: 0;
    padding: 0; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li:not(:last-of-type) {
      margin-bottom: 1rem; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li input[type="checkbox"],
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li input[type="radio"] {
      display: none; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li > label {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 1rem;
      cursor: pointer;
      font-size: 1.2rem;
      transition: all 0.3s ease;
      background-color: #FFF; }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li > label::before {
        display: block;
        width: 1.5rem;
        transition: all 0.2s ease;
        content: "\f10c";
        font-family: fontawesome; }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li > label img {
        display: inline-block;
        margin: 0 0.5rem; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li input:checked + label::before {
      content: "\f192"; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li div.payment_box {
      margin: 0;
      padding: 0.5rem 1rem;
      border-top: 0; }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li div.payment_box p {
        margin: 0 0 1rem; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li #wc-stripe-cc-form > * {
      display: block;
      float: none;
      width: auto; }
      body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li #wc-stripe-cc-form > * input {
        width: auto; }
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li #wc-stripe-cc-form > .form-row-first,
    body.woocommerce-checkout .woocommerce-checkout-payment#payment > .payment_methods > li #wc-stripe-cc-form > .form-row-last {
      padding-left: 0;
      padding-right: 0; }

body.woocommerce-checkout .woocommerce-checkout-payment#payment .place-order .button {
  display: block;
  padding: 1rem 3rem;
  margin: 0 auto;
  font-size: 1.4rem; }

#address_form {
  padding: 1rem;
  background-color: #e6ebf2; }
  #address_form input[type="text"] {
    background-color: #fff;
    padding: 0.3rem;
    margin-left: 0.5rem; }

.woocommerce-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }
  .woocommerce-pagination > ul.page-numbers {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 2rem 2rem 5rem 2rem; }
    .woocommerce-pagination > ul.page-numbers > li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
      justify-content: center;
      list-style-type: none;
      margin: 0 0.5rem;
      min-width: 3rem;
      color: #d74b2f; }
      .woocommerce-pagination > ul.page-numbers > li:hover {
        background-color: #d74b2f;
        color: #fff; }
      .woocommerce-pagination > ul.page-numbers > li > span,
      .woocommerce-pagination > ul.page-numbers > li > a {
        padding: 1.2rem 1.5rem;
        color: inherit;
        border: 1px solid #d74b2f; }
        .woocommerce-pagination > ul.page-numbers > li > span.prev, .woocommerce-pagination > ul.page-numbers > li > span.next,
        .woocommerce-pagination > ul.page-numbers > li > a.prev,
        .woocommerce-pagination > ul.page-numbers > li > a.next {
          border: none; }
        .woocommerce-pagination > ul.page-numbers > li > span.current,
        .woocommerce-pagination > ul.page-numbers > li > a.current {
          background-color: #d74b2f;
          color: #fff; }

main.woocommerce-page > .wysiwyg > .content > h1 {
  margin-top: 1rem; }

main.woocommerce-page > .wysiwyg > .content > .products {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 1rem -3rem; }
  main.woocommerce-page > .wysiwyg > .content > .products > li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -ms-flex-preferred-size: 17rem;
    flex-basis: 17rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    list-style-type: none;
    margin: 3rem; }
    main.woocommerce-page > .wysiwyg > .content > .products > li .dummy {
      -ms-flex-preferred-size: 17rem;
      flex-basis: 17rem; }
    main.woocommerce-page > .wysiwyg > .content > .products > li > a {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -ms-flex-positive: 1;
      flex-grow: 1; }
      main.woocommerce-page > .wysiwyg > .content > .products > li > a > img {
        max-width: 100%;
        padding-bottom: 1rem; }
      main.woocommerce-page > .wysiwyg > .content > .products > li > a > .price {
        display: block;
        width: 100%;
        text-align: center;
        padding: 1rem;
        font-size: 1.2rem; }
    main.woocommerce-page > .wysiwyg > .content > .products > li > .button {
      -ms-flex-positive: 0;
      flex-grow: 0;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }

body.woocommerce-checkout > main > .wysiwyg > .content > .woocommerce > .woocommerce-thankyou-order-details {
  margin: 0;
  padding: 1rem 0; }
  body.woocommerce-checkout > main > .wysiwyg > .content > .woocommerce > .woocommerce-thankyou-order-details > li {
    padding: 0.5rem;
    list-style-type: none; }

body.woocommerce-checkout > main > .wysiwyg > .content > .woocommerce > .order_details,
body.woocommerce-checkout > main > .wysiwyg > .content > .woocommerce > .customer_details {
  background-color: #e6ebf2;
  margin-bottom: 3rem; }
  body.woocommerce-checkout > main > .wysiwyg > .content > .woocommerce > .order_details > tbody > tr > td,
  body.woocommerce-checkout > main > .wysiwyg > .content > .woocommerce > .customer_details > tbody > tr > td {
    padding: 1rem; }

body.woocommerce-checkout > main > .wysiwyg > .content address {
  margin-bottom: 3rem;
  padding: 1rem;
  background-color: #e6ebf2;
  line-height: 1.3; }

.woocommerce-message,
.woocommerce-error {
  padding: 0.325rem;
  margin: 0 0 1rem;
  text-align: center;
  border-radius: 3px; }
  .woocommerce-message a.button,
  .woocommerce-error a.button, .woocommerce-message button,
  .woocommerce-error button {
    margin: 0 1rem; }

ul.woocommerce-error {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin: 0 0 2rem 0;
  background-color: rgba(215, 75, 47, 0.1);
  border: 1px solid #d74b2f; }
  ul.woocommerce-error > li {
    padding: 0.5rem;
    margin-left: 2rem; }

.shop_table {
  width: 100%; }
  .shop_table thead th {
    padding: 0.5rem;
    font-weight: bold; }
    .shop_table thead th.product-name {
      text-align: left; }
    .shop_table thead th.product-subtotal, .shop_table thead th.product-total {
      text-align: left; }
  .shop_table tbody .cart_item td {
    padding: 0.5rem;
    text-align: center;
    vertical-align: middle; }
    .shop_table tbody .cart_item td.product-name {
      text-align: left;
      font-size: 1.2rem; }
      .shop_table tbody .cart_item td.product-name a {
        display: block; }
      .shop_table tbody .cart_item td.product-name .variation {
        display: -ms-flexbox;
        display: flex;
        padding: 0.25rem 0.5rem;
        font-size: 1rem;
        line-height: 1rem;
        color: #999; }
        .shop_table tbody .cart_item td.product-name .variation:not(:last-of-type) {
          margin: 0 0 0.5rem; }
        .shop_table tbody .cart_item td.product-name .variation > dt {
          margin: 0 0.5rem 0 0; }
        .shop_table tbody .cart_item td.product-name .variation > dd {
          color: #666; }
        .shop_table tbody .cart_item td.product-name .variation p {
          margin: 0;
          line-height: 1rem; }
    .shop_table tbody .cart_item td.product-subtotal, .shop_table tbody .cart_item td.product-total {
      text-align: left;
      font-size: 1.2rem; }
  .shop_table tfoot tr th {
    padding: 0.5rem;
    text-align: right; }
  .shop_table tfoot tr td {
    padding: 0.5rem; }
    .shop_table tfoot tr td ul, .shop_table tfoot tr td ol {
      margin: 0; }
  .shop_table .cart-subtotal,
  .shop_table .order-total {
    font-size: 1.2rem; }

.product-info-tabs {
  margin: 0 0 2rem;
  padding: 0 0 2rem; }
  .product-info-tabs nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .product-info-tabs nav [data-product-tab] {
      display: block;
      padding: 1rem 2rem;
      background-color: rgba(233, 233, 233, 0.5); }
      .product-info-tabs nav [data-product-tab][data-status="active"] {
        color: white; }
      .product-info-tabs nav [data-product-tab]:not(:last-of-type) {
        margin: 0 3px 0 0; }
  .product-info-tabs .tabs [data-product-tab] {
    display: none;
    padding: 1rem; }
    .product-info-tabs .tabs [data-product-tab][data-status="active"] {
      display: block; }
    .product-info-tabs .tabs [data-product-tab] p {
      margin: 0; }
      .product-info-tabs .tabs [data-product-tab] p:not(:last-of-type) {
        margin: 0 0 0.5rem; }

body.woocommerce-order-pay #order_review {
  padding: 1rem; }

body.woocommerce-order-pay #payment input[type="submit"] {
  margin-top: 2rem; }

aside.modal-cart {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  position: fixed;
  top: 3.5rem;
  right: -100%;
  width: 350px;
  height: auto;
  padding: 1rem;
  background-color: #fff;
  opacity: 0;
  transition: all 0.5s ease;
  z-index: 40; }
  aside.modal-cart.active {
    right: 2rem;
    display: block;
    opacity: 1;
    z-index: 40; }
  aside.modal-cart #cart-preview {
    position: relative; }
    aside.modal-cart #cart-preview::after {
      position: absolute;
      content: "";
      top: calc(-1rem - 10px);
      right: -1rem;
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent #fff transparent; }
    aside.modal-cart #cart-preview h4 {
      padding: 0 0.5rem; }
    aside.modal-cart #cart-preview ul.cart-items:not(.empty) {
      padding-bottom: 1rem;
      border-bottom: 1px solid #999999; }
    aside.modal-cart #cart-preview ul.cart-items li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem 0.5rem; }
      aside.modal-cart #cart-preview ul.cart-items li .item-title {
        -ms-flex-preferred-size: 10rem;
        flex-basis: 10rem;
        display: inline-block;
        color: #d74b2f;
        text-decoration: underline; }
      aside.modal-cart #cart-preview ul.cart-items li .amount {
        text-align: right;
        -ms-flex-preferred-size: 4rem;
        flex-basis: 4rem; }
      aside.modal-cart #cart-preview ul.cart-items li > a,
      aside.modal-cart #cart-preview ul.cart-items li > span {
        -ms-flex-positive: 1;
        flex-grow: 1; }
    aside.modal-cart #cart-preview > .cart-total {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 1rem; }
      aside.modal-cart #cart-preview > .cart-total > span {
        -ms-flex-item-align: start;
        align-self: flex-start;
        font-weight: 700; }
      aside.modal-cart #cart-preview > .cart-total > .amount {
        -ms-flex-item-align: end;
        align-self: flex-end; }
    aside.modal-cart #cart-preview .bottom-button {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-radius: 5px; }
      aside.modal-cart #cart-preview .bottom-button a.cart-button {
        margin-top: 1rem;
        color: #fff;
        font-weight: 700; }
        aside.modal-cart #cart-preview .bottom-button a.cart-button::after {
          content: '\f105';
          font-family: fontawesome;
          margin-left: 0.5rem;
          color: inherit; }

.fancy {
  position: relative; }
  .fancy::after {
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    height: 1px;
    width: 100%;
    content: "";
    background-color: #e6ebf2; }
    @media screen and (max-width: 87.3rem) {
      .fancy::after {
        display: none; } }
  .fancy > a {
    position: relative;
    background-color: #fff;
    float: right;
    padding: 0 0 0 2rem;
    color: #d74b2f;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    transform: translateY(50%);
    z-index: 1; }
  .fancy > span {
    position: relative;
    background-color: #fff;
    z-index: 1; }
    @media screen and (min-width: 87.3rem) {
      .fancy > span {
        padding-right: 2rem; } }

main.front > .featured {
  margin-top: 3rem; }
  main.front > .featured > .slide-nav > ul.text-links > .text-link > a[data-active="true"] {
    color: #d74b2f; }
  main.front > .featured > .slide-info {
    display: -ms-flexbox;
    display: flex;
    position: relative; }
    main.front > .featured > .slide-info > .container {
      width: 100%; }
      main.front > .featured > .slide-info > .container[data-active="false"] {
        display: none; }
      main.front > .featured > .slide-info > .container[data-active="true"] {
        display: block; }
      @media screen and (min-width: 87.3rem) {
        main.front > .featured > .slide-info > .container {
          transition: opacity 1s ease-in-out; }
          main.front > .featured > .slide-info > .container[data-active="false"] {
            opacity: 0;
            visibility: hidden; }
          main.front > .featured > .slide-info > .container[data-active="true"] {
            opacity: 1;
            visibility: visible; } }

select {
  padding: 1rem;
  height: 3rem;
  min-width: 16rem;
  border-radius: 0;
  font-size: 1.2rem;
  color: #fff;
  background-color: #d74b2f;
  max-width: 10rem; }
  select.hidden {
    visibility: hidden;
    opacity: 0; }

.filters > form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin: 0 0 4rem;
  border-bottom: 1px solid #e6ebf2; }
  .filters > form > .separator {
    font-weight: bold;
    font-family: Oswald, SourceSans light;
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #666666;
    text-decoration: underline; }
  .filters > form > label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 15rem;
    flex-basis: 15rem;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    padding: 2rem 0.5rem;
    font-weight: bold;
    font-family: Oswald, SourceSans light;
    font-size: 1.7rem;
    color: #666666; }
    .filters > form > label[for="filter_by"] {
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
      .filters > form > label[for="filter_by"] > .input-wrapper {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: column wrap;
        flex-flow: column wrap;
        padding-left: 0.5rem; }
        .filters > form > label[for="filter_by"] > .input-wrapper > .row {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          font-size: 1.3rem; }
          .filters > form > label[for="filter_by"] > .input-wrapper > .row:not(:last-of-type) {
            margin-bottom: 0.5rem; }
          .filters > form > label[for="filter_by"] > .input-wrapper > .row > input {
            margin-right: 0.5rem; }
            .filters > form > label[for="filter_by"] > .input-wrapper > .row > input:hover {
              cursor: pointer; }
      @media screen and (max-width: 87.3rem) {
        .filters > form > label[for="filter_by"] > span {
          margin-bottom: 0.8rem; }
        .filters > form > label[for="filter_by"] > .input-wrapper .row {
          margin-bottom: 1rem; } }
    @media screen and (min-width: 87.3rem) {
      .filters > form > label {
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        -ms-flex-pack: center;
        justify-content: center; } }
    @media screen and (max-width: 47.93rem) {
      .filters > form > label {
        -ms-flex-flow: column wrap;
        flex-flow: column wrap; } }
    .filters > form > label > select,
    .filters > form > label > input {
      position: absolute;
      top: 50%;
      margin-left: 1rem;
      transform: translateY(-50%);
      opacity: 1;
      transition: opacity 0.3s ease-in-out; }
      @media screen and (max-width: 47.93rem) {
        .filters > form > label > select,
        .filters > form > label > input {
          position: initial;
          top: 0;
          display: block;
          width: 100%;
          margin: 1rem 0 0;
          height: 4rem;
          transform: translateY(0); } }
    .filters > form > label > input {
      border: none;
      background-color: #d74b2f;
      color: #fff;
      padding: 0.2rem 0.5rem;
      border-radius: 0.2rem; }
      .filters > form > label > input.hidden {
        visibility: hidden;
        opacity: 0; }

.drop-list {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  position: absolute;
  margin-left: 0;
  max-height: 30vh;
  width: auto;
  overflow-y: scroll;
  background-color: #fff; }
  .drop-list > li {
    list-style-type: none;
    padding: 0.5rem;
    font-size: 1rem; }
    .drop-list > li:hover {
      background-color: #d74b2f;
      color: #fff;
      cursor: pointer; }
    .drop-list > li.hidden {
      display: none; }
    .drop-list > li.active {
      background-color: #d74b2f;
      color: #fff; }
  .drop-list.hidden {
    visibility: hidden; }

.chosen-container {
  position: relative;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 1rem; }
  @media screen and (max-width: 47.93rem) {
    .chosen-container {
      margin-top: 1rem; } }
  .chosen-container .chosen-drop {
    position: absolute;
    z-index: 1010;
    top: 100%;
    left: -9999px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }
  .chosen-container.chosen-with-drop .chosen-drop {
    left: 0; }
  .chosen-container a {
    cursor: pointer; }
  .chosen-container .search-choice .group-name,
  .chosen-container .chosen-single .group-name {
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;
    color: #fff; }
    .chosen-container .search-choice .group-name::after,
    .chosen-container .chosen-single .group-name::after {
      content: ":";
      padding-left: 2px;
      vertical-align: top; }

.chosen-container-single .chosen-single {
  position: relative;
  display: block;
  overflow: hidden;
  padding: 0 0 0 8px;
  height: 45px;
  background-color: #d74b2f;
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  white-space: nowrap;
  line-height: 45px; }
  .chosen-container-single .chosen-single > span {
    color: #fff; }

.chosen-container-single .chosen-default {
  color: #999; }

.chosen-container-single .chosen-single span {
  display: block;
  overflow: hidden;
  margin-right: 26px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.chosen-container-single .chosen-single-with-deselect span {
  margin-right: 38px; }

.chosen-container-single .chosen-single abbr {
  position: absolute;
  top: 6px;
  right: 26px;
  display: block;
  width: 12px;
  height: 12px;
  background: url("../images/chosen-sprite.png") -42px 1px no-repeat;
  font-size: 1px; }

.chosen-container-single .chosen-single abbr:hover {
  background-position: -42px -10px; }

.chosen-container-single.chosen-disabled .chosen-single abbr:hover {
  background-position: -42px -10px; }

.chosen-container-single .chosen-single div {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 18px;
  height: 100%; }

.chosen-container-single .chosen-single div::before {
  content: "\f0d7";
  position: absolute;
  font-family: fontawesome;
  font-size: 1rem;
  color: #fff;
  height: 1rem;
  width: 1rem; }

.chosen-container-single.chosen-with-drop .chosen-single div::before {
  content: "\f0d8"; }

.chosen-container-single .chosen-search {
  position: relative;
  z-index: 1010;
  margin: 0;
  padding: 3px 4px;
  white-space: nowrap;
  background-color: #fff; }

.chosen-container-single .chosen-search input[type="text"] {
  margin: 1px 0;
  padding: 4px 20px 4px 5px;
  width: 100%;
  height: auto;
  font-size: 1em;
  font-family: sans-serif;
  line-height: normal;
  outline: 0;
  border: 1px solid #aaa;
  border-radius: 0; }

.chosen-container-single .chosen-drop {
  margin-top: -1px;
  border-radius: 0 0 4px 4px;
  background-clip: padding-box; }

.chosen-container-single.chosen-container-single-nosearch .chosen-search {
  position: absolute;
  left: -9999px; }

/* @end */
/* @group Results */
.chosen-container .chosen-results {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 240px;
  background-color: #fff;
  margin-left: 0;
  color: #EEE;
  -webkit-overflow-scrolling: touch; }
  .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 0.5rem;
    list-style: none;
    line-height: 1rem;
    word-wrap: break-word;
    -webkit-touch-callout: none; }
    .chosen-container .chosen-results li.active-result {
      display: list-item;
      cursor: pointer;
      color: #666666;
      font-size: 1rem; }
    .chosen-container .chosen-results li.result-selected {
      display: list-item;
      color: #fff;
      background-color: #d74b2f;
      cursor: default; }
    .chosen-container .chosen-results li.disabled-result {
      display: list-item;
      color: #EEE;
      cursor: default; }
    .chosen-container .chosen-results li.highlighted {
      background-color: #d74b2f;
      color: #FFF; }
    .chosen-container .chosen-results li.no-results {
      padding: 0.5rem;
      display: list-item;
      font-size: 1.3rem;
      color: initial; }
      .chosen-container .chosen-results li.no-results > span {
        display: inline-block;
        padding: 1rem 0; }
    .chosen-container .chosen-results li.group-result {
      display: list-item;
      font-weight: bold;
      cursor: default; }
    .chosen-container .chosen-results li.group-option {
      padding-left: 15px; }
    .chosen-container .chosen-results li em {
      font-style: normal;
      text-decoration: underline; }

/* @end */
/* @group Multi Chosen */
.chosen-container-multi .chosen-choices {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0 1.75rem 0.5rem 0.5rem;
  width: 100%;
  height: auto;
  min-height: 100%;
  border: 0;
  cursor: pointer; }
  .chosen-container-multi .chosen-choices::after {
    position: absolute;
    top: 10px;
    right: 10px;
    content: "\f0d7";
    font-size: 1.5rem;
    font-family: fontawesome;
    cursor: pointer; }
  .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none; }
    .chosen-container-multi .chosen-choices li.search-field {
      margin: 0;
      padding: 0;
      white-space: nowrap; }
      .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
        display: inline-block;
        margin: 0.375rem 0 0;
        padding: 0.5rem 0 0;
        outline: 0;
        background: transparent;
        color: #999;
        font-size: 100%;
        font-family: sans-serif;
        line-height: normal;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        cursor: pointer; }
    .chosen-container-multi .chosen-choices li.search-choice {
      position: relative;
      margin: 0.5rem 0.5rem 0 0;
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
      max-width: 100%;
      color: #EEE;
      line-height: 13px; }
      .chosen-container-multi .chosen-choices li.search-choice span {
        word-wrap: break-word; }
      .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
        position: absolute;
        right: 6px;
        top: 9px;
        display: block;
        width: 12px;
        height: 12px;
        font-size: 1px; }
        .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
          background-position: -42px -10px; }
    .chosen-container-multi .chosen-choices li.search-choice-disabled {
      padding-right: 0.5rem;
      background: transparent;
      text-transform: uppercase;
      color: #DDD; }
    .chosen-container-multi .chosen-choices li.search-choice-focus {
      background: #d4d4d4; }
      .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
        background-position: -42px -10px; }

/* @end */
/* @group Active  */
.chosen-container-active .chosen-single {
  background-color: #d74b2f; }

.chosen-container-active .chosen-choices {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); }

/* @end */
/* @group Disabled Support */
.chosen-disabled {
  opacity: 0.5;
  cursor: default; }
  .chosen-disabled .chosen-single,
  .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default; }

/* @end */
/* @group Retina compatibility */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
  .chosen-rtl .chosen-search input[type="text"],
  .chosen-container-single .chosen-single abbr,
  .chosen-container-single .chosen-single div b,
  .chosen-container-single .chosen-search input[type="text"],
  .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
  .chosen-container .chosen-results-scroll-down span,
  .chosen-container .chosen-results-scroll-up span {
    background-image: url("chosen-sprite@2x.png");
    background-size: 52px 37px;
    background-repeat: no-repeat; } }

.flex-wrapper > .flex-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .flex-wrapper > .flex-row > article {
    max-width: 100%; }
    .flex-wrapper > .flex-row > article > a {
      text-decoration: none;
      color: #000; }
      .flex-wrapper > .flex-row > article > a > h4,
      .flex-wrapper > .flex-row > article > a > .img,
      .flex-wrapper > .flex-row > article > a > p {
        margin-bottom: 1rem; }
      .flex-wrapper > .flex-row > article > a > h4 {
        font-size: 1.2rem; }

gecko-blackout {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; }
  gecko-blackout:not([hidden]) {
    transition-delay: 0s; }
  gecko-blackout[hidden] {
    visibility: hidden;
    opacity: 0; }

GeckoRevealer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  height: 4rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 10%, white 80%); }
  GeckoRevealer[data-active="true"] #trigger::after {
    content: "\f078"; }
  GeckoRevealer #trigger {
    display: block;
    position: relative;
    margin-top: 3rem;
    color: #d74b2f; }
    GeckoRevealer #trigger::after {
      position: absolute;
      top: 0;
      right: -1.2rem;
      height: 1rem;
      width: 1rem;
      content: "\f077";
      font-family: fontawesome;
      font-size: 1rem; }
    GeckoRevealer #trigger:hover {
      cursor: pointer; }

gecko-tabbed-slideshow gecko-slide {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0; }
  gecko-tabbed-slideshow gecko-slide[data-state="in"] {
    z-index: 2;
    opacity: 1; }

gecko-tabbed-slideshow gecko-slideshow-controls {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: white;
  pointer-events: none; }
  gecko-tabbed-slideshow gecko-slideshow-controls > * {
    pointer-events: all; }
  gecko-tabbed-slideshow gecko-slideshow-controls .dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 40px - 0.5rem); }
    gecko-tabbed-slideshow gecko-slideshow-controls .dots > .dot {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 0.9rem;
      height: 0.9rem;
      margin: 0.5rem;
      cursor: pointer;
      border-radius: 100%;
      background-color: white;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out; }
      gecko-tabbed-slideshow gecko-slideshow-controls .dots > .dot[data-active] {
        opacity: 1; }
  gecko-tabbed-slideshow gecko-slideshow-controls .left, gecko-tabbed-slideshow gecko-slideshow-controls .right {
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out; }
  gecko-tabbed-slideshow gecko-slideshow-controls .left {
    text-shadow: -1px 1px 1px black;
    left: 0;
    top: 50%; }
    gecko-tabbed-slideshow gecko-slideshow-controls .left {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-tabbed-slideshow gecko-slideshow-controls .left::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-tabbed-slideshow gecko-slideshow-controls .left:active {
      transform: translateY(-50%) translateX(-6px); }
    @media screen and (min-width: 87.3rem) {
      gecko-tabbed-slideshow gecko-slideshow-controls .left {
        display: none; } }
  gecko-tabbed-slideshow gecko-slideshow-controls .right {
    text-shadow: 1px 1px 1px black;
    right: 0;
    top: 50%; }
    gecko-tabbed-slideshow gecko-slideshow-controls .right {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-tabbed-slideshow gecko-slideshow-controls .right::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-tabbed-slideshow gecko-slideshow-controls .right:active {
      transform: translateY(-50%) translateX(6px); }
    @media screen and (min-width: 87.3rem) {
      gecko-tabbed-slideshow gecko-slideshow-controls .right {
        display: none; } }

gecko-tabbed-slideshow {
  display: block;
  position: relative;
  z-index: 1;
  max-width: 40rem;
  margin: 0 auto; }
  gecko-tabbed-slideshow:not([data-bound]) {
    visibility: hidden; }
  gecko-tabbed-slideshow > .viewport {
    width: 100%;
    height: 600px; }
    gecko-tabbed-slideshow > .viewport > .slides {
      position: relative;
      width: 100%;
      height: 100%; }

gecko-slideshow gecko-slide {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transition: opacity 1s ease-in-out;
  opacity: 0; }
  gecko-slideshow gecko-slide[data-state="in"] {
    z-index: 2;
    opacity: 1; }
  gecko-slideshow gecko-slide > .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    gecko-slideshow gecko-slide > .container > h1 {
      font-size: 4.5rem; }
    gecko-slideshow gecko-slide > .container > p {
      margin-bottom: 2rem;
      font-size: 1.125rem; }
    gecko-slideshow gecko-slide > .container h1,
    gecko-slideshow gecko-slide > .container p,
    gecko-slideshow gecko-slide > .container .button {
      color: #fff;
      letter-spacing: 0.1rem; }
    gecko-slideshow gecko-slide > .container h1,
    gecko-slideshow gecko-slide > .container p {
      padding: 1rem 0; }

gecko-slideshow gecko-slideshow-controls {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  color: white;
  pointer-events: none; }
  gecko-slideshow gecko-slideshow-controls > * {
    pointer-events: all; }
  gecko-slideshow gecko-slideshow-controls .dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 40px - 0.5rem); }
    gecko-slideshow gecko-slideshow-controls .dots > .dot {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 0.9rem;
      height: 0.9rem;
      margin: 0.5rem;
      cursor: pointer;
      border-radius: 100%;
      background-color: white;
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out; }
      gecko-slideshow gecko-slideshow-controls .dots > .dot[data-active] {
        opacity: 1; }
  gecko-slideshow gecko-slideshow-controls .left, gecko-slideshow gecko-slideshow-controls .right {
    position: absolute;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out; }
  gecko-slideshow gecko-slideshow-controls .left {
    text-shadow: -1px 1px 1px black;
    left: 0;
    top: 50%; }
    gecko-slideshow gecko-slideshow-controls .left {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow gecko-slideshow-controls .left::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow gecko-slideshow-controls .left:active {
      transform: translateY(-50%) translateX(-6px); }
  gecko-slideshow gecko-slideshow-controls .right {
    text-shadow: 1px 1px 1px black;
    right: 0;
    top: 50%; }
    gecko-slideshow gecko-slideshow-controls .right {
      display: -ms-inline-flexbox;
      display: inline-flex;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      color: white;
      font-size: 4rem; }
      gecko-slideshow gecko-slideshow-controls .right::before {
        font-family: FontAwesome;
        content: ""; }
    gecko-slideshow gecko-slideshow-controls .right:active {
      transform: translateY(-50%) translateX(6px); }

gecko-slideshow {
  display: block;
  position: relative;
  z-index: 1;
  border-bottom: 5px solid #fff; }
  gecko-slideshow:not([data-bound]) {
    visibility: hidden; }
  gecko-slideshow > .viewport {
    width: 100%;
    height: 600px; }
    gecko-slideshow > .viewport > .slides {
      position: relative;
      width: 100%;
      height: 100%; }

main > .wysiwyg > .content form > .gform_body > ul > li.gform_validation_container {
  display: none; }

body > .newsletter {
  padding: 1.125rem 0;
  text-align: center;
  background-color: #e6ebf2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }
  body > .newsletter > .gform_wrapper.gform_validation_error > form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap; }
    body > .newsletter > .gform_wrapper.gform_validation_error > form .gform_body {
      padding: 1rem; }
    body > .newsletter > .gform_wrapper.gform_validation_error > form .validation_error {
      margin-bottom: 1rem;
      color: #d74b2f; }
    body > .newsletter > .gform_wrapper.gform_validation_error > form .validation_message {
      padding-left: 0.5rem;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }
  body > .newsletter > .gform_wrapper > form {
    display: -ms-flexbox;
    display: flex;
    padding: 0 1rem; }
    @media screen and (max-width: 87.3rem) {
      body > .newsletter > .gform_wrapper > form {
        -ms-flex-flow: column wrap;
        flex-flow: column wrap; } }
    @media screen and (max-width: 87.3rem) {
      body > .newsletter > .gform_wrapper > form > .gform_body {
        margin: 0 1rem; } }
    body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields > li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields > li.gform_validation_container {
        display: none; }
      body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields > li.gfield_error > .validation_message {
        color: #d74b2f; }
      body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields > li.gfield_error > .ginput_container input {
        border: 2px solid #d74b2f;
        background-color: rgba(251, 171, 156, 0.5); }
    body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields input[type="email"] {
      width: 100%;
      padding: 1rem;
      background-color: #fff; }
    body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields label {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      color: #666666;
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0 1rem; }
      body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields label > .gfield_required {
        display: none; }
    @media screen and (max-width: 87.3rem) {
      body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields label,
      body > .newsletter > .gform_wrapper > form > .gform_body > .gform_fields input {
        margin-bottom: 1rem; } }
    body > .newsletter > .gform_wrapper > form > .gform_footer input[type="submit"] {
      margin-left: 0.5rem;
      height: 100%; }

body.page-template-default > main .content {
  margin: 3rem 0; }
  body.page-template-default > main .content > .img {
    padding-bottom: 50%;
    margin-bottom: 2rem; }

body > footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  margin: 0 auto;
  padding: 3.25rem 2rem;
  font-size: 1.125rem;
  color: #fff;
  background-color: #666666; }
  @media screen and (min-width: 87.3rem) {
    body > footer {
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; } }
  body > footer > .copyright {
    text-align: center;
    margin-bottom: 1rem; }
  body > footer nav {
    text-align: center; }
    body > footer nav ul > li {
      display: inline-block;
      border-left: solid 1px #fff; }
      body > footer nav ul > li:first-child {
        border-style: none; }
      body > footer nav ul > li:last-child a {
        margin-right: 0; }
      body > footer nav ul > li:hover > a {
        border-bottom: 1px solid #fff; }
      body > footer nav ul > li.menu-item-5171 a::before {
        content: "\f0e0"; }
      body > footer nav ul > li.menu-item-5172 a::before {
        content: "\f095"; }
      body > footer nav ul > li > a {
        display: block;
        margin: 0 1rem;
        color: #fff; }
        body > footer nav ul > li > a::before {
          margin-right: 0.5rem;
          font-family: fontawesome;
          color: #999; }

body > header.desktop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 10; }
  @media screen and (max-width: 47rem) {
    body > header.desktop {
      display: none; } }
  html.touch body > header.desktop {
    display: none; }
  body > header.desktop .banner {
    padding: 0 2rem;
    text-transform: uppercase;
    background-color: #e6ebf2; }
    body > header.desktop .banner > .flex-row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around; }
      body > header.desktop .banner > .flex-row a {
        display: inline-block;
        position: relative;
        font-size: 1.2rem;
        padding: 1rem 2rem; }
        body > header.desktop .banner > .flex-row a::before {
          position: absolute;
          font-family: fontawesome;
          text-align: center;
          color: #999999; }
      body > header.desktop .banner > .flex-row > .left,
      body > header.desktop .banner > .flex-row > .right {
        display: block;
        width: 100%; }
      body > header.desktop .banner > .flex-row > .left {
        -ms-flex-line-pack: start;
        align-content: flex-start; }
        body > header.desktop .banner > .flex-row > .left > span {
          font-size: 1.2rem; }
          body > header.desktop .banner > .flex-row > .left > span.account > a {
            padding-left: 0;
            padding-right: 0;
            font-size: inherit;
            text-decoration: underline;
            color: #d74b2f; }
          body > header.desktop .banner > .flex-row > .left > span.login > a::before {
            left: 0.5rem;
            content: "\f007"; }
      body > header.desktop .banner > .flex-row > .right {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end; }
        body > header.desktop .banner > .flex-row > .right > .social {
          display: -ms-flexbox;
          display: flex;
          border-left: 1px solid #fff;
          border-right: 1px solid #fff; }
          body > header.desktop .banner > .flex-row > .right > .social > a {
            -ms-flex-positive: 1;
            flex-grow: 1; }
            body > header.desktop .banner > .flex-row > .right > .social > a i {
              color: #999999;
              transition: color 0.2s ease-in-out; }
              body > header.desktop .banner > .flex-row > .right > .social > a i:hover {
                color: #d74b2f; }
            body > header.desktop .banner > .flex-row > .right > .social > a:hover {
              color: #d74b2f; }
              body > header.desktop .banner > .flex-row > .right > .social > a:hover::before {
                color: inherit; }
            body > header.desktop .banner > .flex-row > .right > .social > a::before {
              left: 0;
              width: 100%; }
        body > header.desktop .banner > .flex-row > .right a.appt::before {
          left: 0.5rem;
          content: "\f017"; }
        body > header.desktop .banner > .flex-row > .right a.fb::before {
          content: "\f09a"; }
        body > header.desktop .banner > .flex-row > .right a.tw::before {
          content: "\f099"; }
        body > header.desktop .banner > .flex-row > .right a.yt::before {
          content: "\f16a"; }
        body > header.desktop .banner > .flex-row > .right a.cart {
          position: relative;
          padding: 1rem 2rem 1rem 4rem; }
          body > header.desktop .banner > .flex-row > .right a.cart::before {
            left: 2rem;
            content: "\f291"; }
          body > header.desktop .banner > .flex-row > .right a.cart::after {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            transform: translateY(50%);
            font-family: fontawesome;
            content: "\f107"; }
    body > header.desktop .banner a {
      color: #333; }
  body > header.desktop .branding {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 2rem;
    border-bottom: solid 1px #e6ebf2; }
    body > header.desktop .branding > a {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }
    body > header.desktop .branding nav ul:not(.sub-menu) > li {
      position: relative;
      display: inline-block; }
      body > header.desktop .branding nav ul:not(.sub-menu) > li > a {
        padding: 2rem 1rem; }
      body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu {
        display: -ms-flexbox;
        display: flex;
        top: calc(100% + 1px);
        left: 50%;
        transform: translateX(-50%);
        min-width: 12rem; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu::before {
          position: absolute;
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          height: 0;
          width: 0;
          content: "";
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #d74b2f transparent; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children {
          position: relative; }
          body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children > a::after {
            position: absolute;
            right: 15%;
            content: "\f0da";
            font-family: fontawesome;
            font-size: 1rem;
            color: #d74b2f; }
          body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children > .sub-menu {
            opacity: 0;
            position: absolute;
            top: 6px;
            left: 100%;
            width: 100%;
            padding: 0 0.1rem; }
            body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children > .sub-menu > li {
              background-color: #e6ebf2; }
              body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children > .sub-menu > li:first-of-type {
                border-top: 1px solid #d74b2f; }
              body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children > .sub-menu > li:hover {
                background-color: #dadfe6; }
              body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children > .sub-menu > li a {
                line-height: 2;
                padding: 0.5rem; }
          body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children:hover > .sub-menu {
            opacity: 1;
            transition: opacity 0.2s linear; }
            body > header.desktop .branding nav ul:not(.sub-menu) > li:hover > .sub-menu > li.menu-item-has-children:hover > .sub-menu > li > a {
              color: #d74b2f; }
      body > header.desktop .branding nav ul:not(.sub-menu) > li.current-menu-item, body > header.desktop .branding nav ul:not(.sub-menu) > li.current_page_parent, body > header.desktop .branding nav ul:not(.sub-menu) > li.current-menu-parent {
        border-bottom: 1px solid #d74b2f; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li.current-menu-item::before, body > header.desktop .branding nav ul:not(.sub-menu) > li.current_page_parent::before, body > header.desktop .branding nav ul:not(.sub-menu) > li.current-menu-parent::before {
          position: absolute;
          top: calc(100% - 10px);
          left: 50%;
          border: 1px solid #d74b2f;
          transform: translateX(-50%);
          height: 0;
          width: 0;
          content: "";
          border-style: solid;
          border-width: 0 10px 10px 10px;
          border-color: transparent transparent #d74b2f transparent; }
      body > header.desktop .branding nav ul:not(.sub-menu) > li > .sub-menu {
        display: none;
        -ms-flex-flow: column nowrap;
        flex-flow: column nowrap;
        position: absolute;
        height: auto;
        width: auto;
        background-color: #e6ebf2;
        border-top: 1px solid #d74b2f; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li > .sub-menu > li > a {
          display: block;
          padding: 1rem;
          color: #d74b2f; }
          body > header.desktop .branding nav ul:not(.sub-menu) > li > .sub-menu > li > a:hover {
            background-color: #dadfe6; }
      body > header.desktop .branding nav ul:not(.sub-menu) > li > a {
        display: block;
        font-size: 1.125rem;
        color: #d74b2f; }
      body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search {
        position: relative;
        padding: 1rem; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search:hover {
          cursor: pointer; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search::before {
          content: "\f002";
          display: inline-block;
          font-family: fontawesome;
          color: #d74b2f;
          font-size: 1.2rem; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search.active::before {
          content: "\f00d"; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search.active > form {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
          border: 2px solid #999999; }
        body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search > form {
          display: none;
          position: absolute;
          top: 5rem;
          right: 0;
          transition: position 1s linear; }
          body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search > form > input {
            display: inline-block;
            padding: 0.5rem;
            background-color: #fff; }
            body > header.desktop .branding nav ul:not(.sub-menu) > li.menu-header-search > form > input[type="submit"] {
              background-color: #d74b2f;
              color: #fff; }

body > header.touch {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #e6ebf2; }
  @media screen and (max-width: 47rem) {
    body > header.touch {
      display: -ms-flexbox;
      display: flex; } }
  html.touch body > header.touch {
    display: -ms-flexbox;
    display: flex; }
  body > header.touch > .toggle-touch-menu {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 2.5rem;
    padding: 0.2em;
    cursor: pointer; }
    body > header.touch > .toggle-touch-menu::before {
      font-family: FontAwesome;
      width: 1em;
      height: 1em;
      vertical-align: top;
      content: "\f0c9"; }
  body > header.touch > .logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.2rem; }

body > aside.touch-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105;
  width: 80vw;
  max-width: 25rem;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: white;
  transition: left 0.3s ease-in-out; }
  body > aside.touch-menu[hidden] {
    left: -100%; }
  body > aside.touch-menu header {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 1rem;
    border-bottom: 1px solid black; }
  body > aside.touch-menu nav {
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    font-size: 1.6rem;
    overflow: auto;
    overflow-x: hidden;
    background-color: #e6ebf2; }
    body > aside.touch-menu nav .menu-primary-container {
      position: relative;
      height: 100%; }
      body > aside.touch-menu nav .menu-primary-container #menu-primary {
        position: absolute;
        left: 0;
        width: 100%;
        transition: all 0.5s ease; }
        body > aside.touch-menu nav .menu-primary-container #menu-primary.offset {
          left: -100%; }
        body > aside.touch-menu nav .menu-primary-container #menu-primary li {
          display: block;
          width: 100%; }
          body > aside.touch-menu nav .menu-primary-container #menu-primary li a {
            position: relative;
            display: block;
            padding: 0.8rem 2rem 0.8rem 0.5rem;
            width: 100%; }
          body > aside.touch-menu nav .menu-primary-container #menu-primary li.current-menu-parent, body > aside.touch-menu nav .menu-primary-container #menu-primary li.current_page_item {
            background-color: #d74b2f; }
            body > aside.touch-menu nav .menu-primary-container #menu-primary li.current-menu-parent > a, body > aside.touch-menu nav .menu-primary-container #menu-primary li.current_page_item > a {
              color: #fff; }
          body > aside.touch-menu nav .menu-primary-container #menu-primary li.chosen > .sub-menu {
            display: block;
            left: 100%; }
            body > aside.touch-menu nav .menu-primary-container #menu-primary li.chosen > .sub-menu .parent a {
              color: #d74b2f;
              border-bottom: 3px solid black;
              font-weight: bold;
              font-size: 1.5rem;
              margin-bottom: 1rem; }
              body > aside.touch-menu nav .menu-primary-container #menu-primary li.chosen > .sub-menu .parent a:after {
                display: none; }
          body > aside.touch-menu nav .menu-primary-container #menu-primary li .sub-menu {
            position: absolute;
            left: 100%;
            top: 0;
            display: none;
            width: 100%;
            transition: all 0.5s ease; }
            body > aside.touch-menu nav .menu-primary-container #menu-primary li .sub-menu.offset {
              left: 0; }
            body > aside.touch-menu nav .menu-primary-container #menu-primary li .sub-menu li a {
              font-size: 1.2rem; }
            body > aside.touch-menu nav .menu-primary-container #menu-primary li .sub-menu .sub-menu {
              position: absolute;
              left: 100%; }
          body > aside.touch-menu nav .menu-primary-container #menu-primary li.menu-item-has-children > a::after {
            position: absolute;
            top: 50%;
            right: 0.5rem;
            transform: translateY(-50%);
            content: "\f105";
            font-size: 2.5rem;
            font-family: fontawesome; }
          body > aside.touch-menu nav .menu-primary-container #menu-primary li.menu-header-search {
            position: absolute;
            margin-top: 2rem;
            padding: 0.5rem; }
            body > aside.touch-menu nav .menu-primary-container #menu-primary li.menu-header-search > form {
              position: relative; }
              body > aside.touch-menu nav .menu-primary-container #menu-primary li.menu-header-search > form > input[type="text"] {
                display: block;
                width: 100%;
                background-color: #fff;
                padding: 0.3rem; }
              body > aside.touch-menu nav .menu-primary-container #menu-primary li.menu-header-search > form > input[type="submit"] {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                height: 100%;
                background-color: #d74b2f;
                color: #fff;
                padding: 0.3rem; }
  body > aside.touch-menu footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-top: 1px solid black;
    padding: 1rem;
    background-color: #e6ebf2; }
    body > aside.touch-menu footer[data-active="true"] #back {
      visibility: visible;
      position: relative;
      display: block;
      padding: 0 0 0 2.5rem;
      font-size: 1.2rem;
      text-transform: uppercase; }
      body > aside.touch-menu footer[data-active="true"] #back:before {
        position: absolute;
        left: 0.5rem;
        font-family: fontawesome;
        font-size: 1.2rem;
        content: "\f060"; }
    body > aside.touch-menu footer #back {
      visibility: hidden; }

.wysiwyg h1 {
  font-size: 3rem; }
  @media screen and (max-width: 87.3rem) {
    .wysiwyg h1 {
      font-size: 2rem; } }

.wysiwyg h2 {
  font-size: 2.3rem; }
  @media screen and (max-width: 87.3rem) {
    .wysiwyg h2 {
      font-size: 1.3rem; } }

.wysiwyg h3 {
  font-size: 1.9rem; }
  @media screen and (max-width: 87.3rem) {
    .wysiwyg h3 {
      font-size: 1.3rem; } }

.wysiwyg h4 {
  font-size: 1.4rem; }

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4 {
  font-weight: 700;
  font-family: Oswald, SourceSans light;
  color: #666666;
  line-height: 1.5; }

.wysiwyg p {
  line-height: 1.3;
  margin: 1rem 0; }

.wysiwyg b, .wysiwyg strong {
  font-weight: bold; }

.wysiwyg i, .wysiwyg em {
  font-style: italic; }

.wysiwyg ul {
  list-style: disc;
  margin-left: 1rem; }

.wysiwyg ol {
  counter-reset: ol; }
  .wysiwyg ol > li {
    counter-increment: ol; }
    .wysiwyg ol > li::before {
      content: counter(ol);
      margin-right: 0.5rem; }

.wysiwyg a {
  color: #d74b2f; }

.wysiwyg button, .wysiwyg a.button, .wysiwyg input[type="button"] {
  display: inline-block;
  padding: 1rem;
  color: #fff;
  text-decoration: none; }

.wysiwyg input[type="text"], .wysiwyg input[type="tel"], .wysiwyg input[type="email"], .wysiwyg textarea {
  border: 1px solid black; }

ul.pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 2rem 2rem 5rem 2rem; }
  @media screen and (max-width: 47.93rem) {
    ul.pagination {
      display: block;
      -ms-flex-align: center;
      align-items: center; } }
  ul.pagination > li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    color: #d74b2f;
    text-decoration: none;
    list-style-type: none; }
    ul.pagination > li:hover {
      background-color: #d74b2f;
      color: #fff; }
    ul.pagination > li.prev, ul.pagination > li.next {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      padding: 0 1rem; }
      ul.pagination > li.prev > a,
      ul.pagination > li.prev > span, ul.pagination > li.next > a,
      ul.pagination > li.next > span {
        text-transform: uppercase;
        text-decoration: none;
        color: inherit; }
      ul.pagination > li.prev::before, ul.pagination > li.prev::after, ul.pagination > li.next::before, ul.pagination > li.next::after {
        font-family: fontawesome;
        color: inherit; }
    ul.pagination > li.prev::before {
      padding-right: 0.5rem;
      content: '\f104'; }
    ul.pagination > li.next::after {
      padding-left: 0.5rem;
      content: '\f105'; }
    ul.pagination > li.page-no {
      margin: 0 0.5rem;
      min-width: 3rem; }
      ul.pagination > li.page-no > a,
      ul.pagination > li.page-no > span {
        padding: 1.2rem 1.5rem;
        text-decoration: none;
        color: inherit; }
      ul.pagination > li.page-no > .page-numbers {
        border: 1px solid #d74b2f; }
        ul.pagination > li.page-no > .page-numbers.current {
          background-color: #d74b2f;
          color: #fff; }
        ul.pagination > li.page-no > .page-numbers.prev, ul.pagination > li.page-no > .page-numbers.next {
          border: none; }
        @media screen and (max-width: 47.93rem) {
          ul.pagination > li.page-no > .page-numbers {
            display: none; }
            ul.pagination > li.page-no > .page-numbers.prev, ul.pagination > li.page-no > .page-numbers.next, ul.pagination > li.page-no > .page-numbers.current {
              display: block; } }

.screen-reader-text {
  display: none; }

.nav-links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 3rem 0; }
  .nav-links .title {
    display: block;
    padding: 0 0.5rem 0.5rem;
    width: 100%;
    text-align: center; }
  .nav-links a,
  .nav-links span {
    margin: 0 0.5rem;
    border: 1px solid #d74b2f;
    padding: 1rem;
    color: #d74b2f; }
    .nav-links a.prev, .nav-links a.next,
    .nav-links span.prev,
    .nav-links span.next {
      border: none;
      color: #d74b2f; }
    .nav-links a.current,
    .nav-links span.current {
      background-color: #d74b2f;
      color: #fff; }
    @media screen and (max-width: 47.93rem) {
      .nav-links a,
      .nav-links span {
        display: none; }
        .nav-links a.prev, .nav-links a.next, .nav-links a.current,
        .nav-links span.prev,
        .nav-links span.next,
        .nav-links span.current {
          display: block; } }

body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section {
  position: relative; }
  body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 5rem;
    border-right: 1px solid #e6ebf2;
    content: "";
    z-index: -1; }
    @media screen and (min-width: 87.3rem) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section::before {
        width: 50%; } }
    @media screen and (max-width: 47.93rem) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section::before {
        display: none; } }
  body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .no-results {
    width: 100%;
    text-align: center;
    font-size: 1.5rem; }
  body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .year {
    position: relative;
    display: block;
    padding: 1rem;
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    background-color: #666666; }
    @media screen and (min-width: 87.3rem) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .year {
        transform: translateY(1rem); } }
  body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    position: relative;
    -ms-flex-pack: justify;
    justify-content: space-between;
    min-height: 15rem;
    margin-bottom: 2rem; }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:hover .dot {
      background-color: #d74b2f; }
    @media screen and (min-width: 768px) and (max-width: 87.3rem) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit {
        padding: 1rem 1rem 1rem 10rem;
        -ms-flex-pack: distribute;
        justify-content: space-around; } }
    @media screen and (max-width: 767px) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit {
        -ms-flex-pack: center;
        justify-content: center;
        margin: 4rem 0;
        padding: 0.5rem; } }
    @media screen and (min-width: 87.3rem) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit.alt {
        -ms-flex-pack: end;
        justify-content: flex-end; }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit.alt > .bubble {
          left: 0;
          margin-left: 4rem; }
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit.alt > .bubble::before {
            right: -36px;
            transform: rotate(180deg) translateY(50%); }
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit.alt > .bubble.is-hidden {
            transform: translateX(5%); }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit.alt > .info {
          -ms-flex-align: start;
          align-items: flex-start; }
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit.alt > .info.is-hidden {
            transform: translateX(-5%); } }
    @media screen and (min-width: 87.3rem) {
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:not(.alt) > .bubble {
        right: 0;
        margin-right: 4rem; }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:not(.alt) > .bubble::before {
          left: -36px; }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:not(.alt) > .bubble.is-hidden {
          transform: translateX(-5%); }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:not(.alt) > .info {
        -ms-flex-align: end;
        align-items: flex-end; }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:not(.alt) > .info.is-hidden {
          transform: translateX(5%); } }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit:last-of-type {
      margin-bottom: 0; }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .dot {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      position: absolute;
      top: calc(50% - 35px);
      left: calc(50% - 35px);
      transform: scale(1);
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 5px solid #fff;
      background-color: #e6ebf2;
      visibility: visible;
      opacity: 1;
      transition: all 1s ease; }
      @media screen and (max-width: 87.3rem) {
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .dot {
          left: calc(5rem - 35px); } }
      @media screen and (max-width: 767px) {
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .dot {
          display: none; } }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .dot.is-hidden {
        opacity: 0;
        transform: scale(0);
        visibility: hidden; }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .dot::before {
        position: absolute;
        top: calc(50% - 0.75rem);
        left: calc(50% - 0.75rem);
        transform: trasnlate(-50%, -50%);
        font-family: fontawesome;
        color: #fff;
        content: "\f03e";
        font-size: 1.5rem; }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column wrap;
      flex-flow: column wrap;
      -ms-flex-pack: center;
      justify-content: center;
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      transition: all 1s ease; }
      @media screen and (min-width: 87.3rem) {
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info {
          -ms-flex-preferred-size: 38rem;
          flex-basis: 38rem; } }
      @media screen and (max-width: 47.93rem) {
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info {
          -ms-flex-align: center;
          align-items: center; } }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info.is-hidden {
        opacity: 0;
        visibility: hidden; }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info > .date {
        margin-bottom: 0.7rem;
        font-size: 1.6rem;
        font-family: Oswald, SourceSans light;
        font-weight: bold;
        color: #666666; }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info > .artist {
        font-size: 1.3rem; }
        @media screen and (max-width: 47.93rem) {
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .info > .artist {
            padding: 1.5rem 0; } }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      max-width: 32rem;
      margin: 1rem 0;
      background-color: #fff;
      border: 2px solid #e6ebf2;
      padding: 2rem;
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
      transition: all 1s ease; }
      @media screen and (min-width: 87.3rem) {
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble {
          position: absolute;
          top: -5rem;
          width: 100%; }
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble::before {
            position: absolute;
            top: 55%;
            transform: translateY(-50%);
            content: '';
            height: 0;
            width: 0; } }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble::before {
        content: "";
        background-image: url("../images/arrow.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 50px;
        width: 50px; }
        @media screen and (max-width: 87.3rem) {
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble::before {
            display: none; } }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble.is-hidden {
        opacity: 0;
        visibility: hidden; }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble > .img {
        -ms-flex-preferred-size: 15rem;
        flex-basis: 15rem;
        min-width: 15rem;
        background-size: contain; }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble > span {
        display: block;
        text-align: center;
        margin-top: 1rem; }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .primary > .timeline > section > .exhibit > .bubble > span > a {
          padding: 1rem; }

main .wysiwyg > .title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e6ebf2;
  padding: 3rem 0 3rem; }
  @media screen and (max-width: 47.93rem) {
    main .wysiwyg > .title {
      display: block;
      padding: 1rem 0;
      text-align: center; }
      main .wysiwyg > .title > h1 {
        padding-bottom: 1rem; } }
  @media screen and (max-width: 47.93rem) {
    main .wysiwyg > .title.search {
      -ms-flex-flow: column wrap;
      flex-flow: column wrap; } }
  main .wysiwyg > .title.search > form {
    position: relative;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    margin-top: 2rem; }
    @media screen and (min-width: 87.3rem) {
      main .wysiwyg > .title.search > form {
        margin-top: 0;
        -ms-flex-preferred-size: 20rem;
        flex-basis: 20rem;
        -ms-flex-item-align: end;
        align-self: flex-end; } }
    main .wysiwyg > .title.search > form > label {
      position: absolute;
      top: 0.5rem;
      right: 0; }
      main .wysiwyg > .title.search > form > label[for="s"]:hover {
        cursor: pointer; }
      main .wysiwyg > .title.search > form > label::after {
        display: inline-block;
        content: "\f002";
        font-family: fontawesome;
        color: #d74b2f;
        font-size: 1.2rem; }
    main .wysiwyg > .title.search > form > input[type="text"] {
      width: 100%;
      border: none;
      border-bottom: 1px solid #d74b2f;
      padding: 0.5rem 1.5rem 0.5rem 0; }
      main .wysiwyg > .title.search > form > input[type="text"]:focus {
        outline: none; }

@media screen and (min-width: 87.3rem) {
  main.artists-page > .wysiwyg > .content > section > .flex-wrapper > .flex-row {
    margin: 0 -1rem; }
    main.artists-page > .wysiwyg > .content > section > .flex-wrapper > .flex-row > article {
      max-width: 16rem; } }

main.artists-page > .wysiwyg > .content > section > .flex-wrapper > .flex-row > article {
  -ms-flex-preferred-size: 16rem;
  flex-basis: 16rem;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 0 1rem 2rem; }
  main.artists-page > .wysiwyg > .content > section > .flex-wrapper > .flex-row > article > a > .img {
    padding-bottom: 100%;
    margin-bottom: 1rem; }
  main.artists-page > .wysiwyg > .content > section > .flex-wrapper > .flex-row > article > a > span.meta {
    display: block;
    text-align: center; }

main.artists-page > .wysiwyg > .content > section > .flex-wrapper > .flex-row > .dummy {
  -ms-flex-preferred-size: 16rem;
  flex-basis: 16rem;
  margin: 0 1rem 2rem;
  max-width: 16rem; }

body.page-template-page-contact-info > main iframe {
  width: 100%; }

body.page-template-page-contact-info > main h4 + ul {
  margin-top: 1rem; }

body.page-template-page-contact-info > main ul {
  margin-bottom: 1rem; }
  body.page-template-page-contact-info > main ul li {
    margin: 0 1rem;
    padding: 0.5rem; }

body.page-template-page-contact-info > main > .wysiwyg > .content {
  margin: 3rem 0; }
  body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper {
    margin: 0 -1rem; }
    body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper > .flex-row {
      -ms-flex-pack: justify;
      justify-content: space-between; }
      body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left,
      body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right,
      body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .sidebar {
        margin: 0 1rem; }
      body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .sidebar {
        -ms-flex-preferred-size: 15rem;
        flex-basis: 15rem;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 0;
        flex-shrink: 0; }
        @media screen and (min-width: 87.3rem) {
          body.page-template-page-contact-info > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .sidebar > p {
            margin: 0; } }

main.error-404 {
  padding: 3rem 0; }

body.post-type-archive-exhibition > main > .wysiwyg > h1 {
  border-bottom: 1px solid #e6ebf2;
  padding-bottom: 3rem; }

body.post-type-archive-exhibition > main > .wysiwyg > .content {
  margin-top: 4rem; }
  body.post-type-archive-exhibition > main > .wysiwyg > .content > .dates {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    margin: 4rem 0; }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .dates > h4 {
      margin-bottom: 1rem; }
    body.post-type-archive-exhibition > main > .wysiwyg > .content > .dates > ul {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      body.post-type-archive-exhibition > main > .wysiwyg > .content > .dates > ul > li {
        list-style-type: none;
        padding: 1rem 0; }
        body.post-type-archive-exhibition > main > .wysiwyg > .content > .dates > ul > li > a {
          color: #d74b2f;
          font-family: Oswald, SourceSans light;
          font-weight: bold;
          padding: 0 1rem; }
          body.post-type-archive-exhibition > main > .wysiwyg > .content > .dates > ul > li > a.active {
            color: #666666; }

main.front {
  max-width: 87.3rem;
  background-color: #fff; }
  @media screen and (min-width: 87.3rem) {
    main.front {
      margin: 5rem auto 0; } }
  main.front > .featured {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 4rem; }
    main.front > .featured > .slide-nav,
    main.front > .featured > .slideshow-wrap,
    main.front > .featured > .slide-info {
      -ms-flex-positive: 1;
      flex-grow: 1; }
    main.front > .featured > .slide-nav,
    main.front > .featured > .slide-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-preferred-size: 20rem;
      flex-basis: 20rem;
      -ms-flex-align: center;
      align-items: center; }
    main.front > .featured > .slide-nav {
      padding: 1rem; }
      @media screen and (max-width: 87.3rem) {
        main.front > .featured > .slide-nav {
          display: none; } }
      main.front > .featured > .slide-nav > ul {
        width: 100%; }
        main.front > .featured > .slide-nav > ul > li {
          color: #999999;
          border-bottom: 1px solid #e6ebf2; }
          main.front > .featured > .slide-nav > ul > li[data-active="true"] {
            color: #d74b2f; }
          main.front > .featured > .slide-nav > ul > li:first-of-type {
            border-top: 1px solid #e6ebf2; }
          main.front > .featured > .slide-nav > ul > li:hover a {
            color: #d74b2f; }
          main.front > .featured > .slide-nav > ul > li > a {
            display: block;
            padding: 3rem; }
    main.front > .featured > .slideshow-wrap {
      -ms-flex-preferred-size: 40rem;
      flex-basis: 40rem; }
      @media screen and (max-width: 87.3rem) {
        main.front > .featured > .slideshow-wrap {
          -ms-flex-preferred-size: 25rem;
          flex-basis: 25rem; } }
    main.front > .featured > .slide-info {
      padding: 1rem; }
      @media screen and (max-width: 87.3rem) {
        main.front > .featured > .slide-info > .container {
          width: 100%;
          text-align: center; } }
      @media screen and (min-width: 87.3rem) {
        main.front > .featured > .slide-info > .container > .info-wrapper {
          padding: 3rem 0;
          border-top: 1px solid #e6ebf2;
          border-bottom: 1px solid #e6ebf2; }
          main.front > .featured > .slide-info > .container > .info-wrapper > h3,
          main.front > .featured > .slide-info > .container > .info-wrapper > p {
            margin-bottom: 1.5rem; } }
      @media screen and (max-width: 87.3rem) {
        main.front > .featured > .slide-info > .container > .info-wrapper > h3,
        main.front > .featured > .slide-info > .container > .info-wrapper > p {
          margin-bottom: 0.7rem; }
        main.front > .featured > .slide-info > .container > .info-wrapper > h3 {
          font-size: 1.4rem; } }
      main.front > .featured > .slide-info > .container > .info-wrapper > .more {
        color: #d74b2f;
        font-weight: bold;
        text-transform: uppercase; }
  @media screen and (min-width: 87.3rem) {
    main.front > section {
      padding: 0 1rem; }
      main.front > section > .flex-wrapper {
        margin: 0 -1rem; } }
  main.front > section > .flex-wrapper > .flex-row {
    width: 100%;
    padding: 3rem 0 1rem; }
    main.front > section > .flex-wrapper > .flex-row > article {
      -ms-flex-preferred-size: 21rem;
      flex-basis: 21rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 21rem;
      margin: 0 1rem;
      margin-bottom: 3rem; }
      main.front > section > .flex-wrapper > .flex-row > article > a > .img {
        padding-bottom: 100%; }

body.page-template-page-galleries > main > .wysiwyg > .content > .flex-wrapper > .flex-row {
  -ms-flex-flow: column wrap;
  flex-flow: column wrap; }
  body.page-template-page-galleries > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .slideshow-wrapper {
    margin: 3rem 0; }
    body.page-template-page-galleries > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .slideshow-wrapper > h3 {
      margin-bottom: 1rem; }
    body.page-template-page-galleries > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .slideshow-wrapper > gecko-slideshow > .viewport > .slides > gecko-slide {
      background-size: contain; }

body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content {
  margin-top: 3rem; }
  body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper {
    margin: 0 -1rem; }
    body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-preferred-size: 30rem;
        flex-basis: 30rem;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 0 1rem;
        margin-bottom: 4rem; }
        body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .img {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-preferred-size: 16rem;
          flex-basis: 16rem;
          -ms-flex-positive: 1;
          flex-grow: 1;
          min-height: 16rem;
          min-width: 16rem; }
          @media screen and (max-width: 87.3rem) {
            body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .img {
              margin-bottom: 1rem; } }
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .img > a {
            display: block;
            height: 100%;
            width: 100%; }
        body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info {
          -ms-flex-preferred-size: 26rem;
          flex-basis: 26rem;
          -ms-flex-positive: 1;
          flex-grow: 1;
          padding: 0 1rem; }
          @media screen and (min-width: 87.3rem) {
            body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info {
              padding: 0 0 0 2rem; } }
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info > span {
            display: block; }
            body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info > span.author {
              font-size: 1.2rem;
              padding-bottom: 0.3rem; }
            body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info > span.date {
              color: #999999;
              padding-bottom: 1rem;
              border-bottom: 1px solid #e6ebf2; }
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info > .more {
            font-family: Oswald, SourceSans light;
            font-weight: bold;
            text-transform: uppercase; }
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info > a h4 {
            padding: 0.5rem 0 0.8rem; }
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info > p {
            margin: 0.5rem 0 0.8rem; }
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info h4,
          body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > article > .info p {
            text-align: left; }
      body.page-template-page-news > main .wysiwyg > section.content-area > .primary > .content > .flex-wrapper > section.flex-row > .dummy {
        -ms-flex-preferred-size: 30rem;
        flex-basis: 30rem;
        -ms-flex-positive: 1;
        flex-grow: 1; }

main.search .clamped-width {
  margin-top: 3rem;
  margin-bottom: 3rem; }
  main.search .clamped-width > h1 > .search-term {
    text-decoration: underline;
    font-style: italic; }
  main.search .clamped-width > .posts {
    margin-top: 1rem; }
    main.search .clamped-width > .posts li {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap; }
      main.search .clamped-width > .posts li:not(:last-of-type) {
        margin-bottom: 4rem; }
      @media (max-width: 47.93rem) {
        main.search .clamped-width > .posts li {
          -ms-flex-direction: column;
          flex-direction: column;
          margin: 0 0 4rem; } }
      main.search .clamped-width > .posts li .img-placeholder {
        -ms-flex-preferred-size: 18rem;
        flex-basis: 18rem;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: block;
        height: 0; }
        @media (max-width: 47.93rem) {
          main.search .clamped-width > .posts li .img-placeholder {
            -ms-flex-preferred-size: auto;
            flex-basis: auto; } }
      main.search .clamped-width > .posts li .img {
        padding-bottom: 16.66667%;
        -ms-flex-preferred-size: 18rem;
        flex-basis: 18rem;
        min-height: 16rem;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: block;
        background-position: top center; }
      main.search .clamped-width > .posts li section {
        -ms-flex-preferred-size: 10rem;
        flex-basis: 10rem;
        max-width: 28rem;
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin-left: 2rem; }
        @media (max-width: 87.3rem) {
          main.search .clamped-width > .posts li section {
            margin-top: 1rem;
            margin-left: 1rem; } }
        @media (max-width: 47.93rem) {
          main.search .clamped-width > .posts li section {
            -ms-flex-preferred-size: auto;
            flex-basis: auto;
            margin-left: 0;
            max-width: 100%;
            text-align: center; } }
        main.search .clamped-width > .posts li section h3 {
          display: block; }
        main.search .clamped-width > .posts li section span {
          display: block;
          padding-top: 1rem;
          color: #666666; }
        main.search .clamped-width > .posts li section p {
          margin: 0 0 1rem;
          color: #333;
          line-height: 1.4; }
        main.search .clamped-width > .posts li section a {
          color: #d74b2f;
          font-weight: 700; }
        main.search .clamped-width > .posts li section .view-all-products {
          position: relative;
          display: inline-block;
          padding: 0.75rem 2.5rem;
          font-weight: normal;
          color: #FFF;
          transition: background 0.2s ease;
          background-color: #d74b2f; }
          main.search .clamped-width > .posts li section .view-all-products::after {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            display: inline-block;
            margin: 0 0 0 1rem;
            height: 1em;
            font-family: fontawesome;
            content: "\f105";
            transition: all 0.2s ease; }
          main.search .clamped-width > .posts li section .view-all-products strong {
            font-weight: bold; }
          main.search .clamped-width > .posts li section .view-all-products:hover {
            background-color: #d9563c; }
            main.search .clamped-width > .posts li section .view-all-products:hover::after {
              right: 0.8rem; }

body.single-artist > main > .clamped-width > article {
  margin-bottom: 3rem; }
  body.single-artist > main > .clamped-width > article > .primary {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 3rem 0; }
    body.single-artist > main > .clamped-width > article > .primary > .meta {
      -ms-flex-preferred-size: 25rem;
      flex-basis: 25rem;
      -ms-flex-positive: 1;
      flex-grow: 1; }
      body.single-artist > main > .clamped-width > article > .primary > .meta > .img {
        padding-bottom: 50%;
        max-height: 30rem;
        background-size: contain;
        background-position: center top; }
        @media screen and (max-width: 87.3rem) {
          body.single-artist > main > .clamped-width > article > .primary > .meta > .img {
            margin-bottom: 2rem; } }
        @media screen and (max-width: 47.93rem) {
          body.single-artist > main > .clamped-width > article > .primary > .meta > .img {
            height: 15rem; } }
    body.single-artist > main > .clamped-width > article > .primary > .content {
      position: relative;
      display: block;
      -ms-flex-preferred-size: 30rem;
      flex-basis: 30rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      transition: height 1s ease-in-out;
      overflow: hidden; }
      @media screen and (min-width: 47.93rem) {
        body.single-artist > main > .clamped-width > article > .primary > .content {
          padding: 0 2rem; } }
      body.single-artist > main > .clamped-width > article > .primary > .content > p {
        margin-top: 0; }
      body.single-artist > main > .clamped-width > article > .primary > .content > ul {
        margin: 0 0 0.5rem; }
        body.single-artist > main > .clamped-width > article > .primary > .content > ul > li {
          margin-bottom: 1rem;
          list-style-type: none;
          line-height: 1.3; }
          body.single-artist > main > .clamped-width > article > .primary > .content > ul > li > span {
            font-weight: bold;
            font-family: Oswald, SourceSans light;
            color: #666666;
            margin-right: 0.4rem; }

body.single-artist > main > .clamped-width > section.flex-wrapper > .flex-row {
  margin: 3rem -1rem; }
  body.single-artist > main > .clamped-width > section.flex-wrapper > .flex-row > article {
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 1rem 3rem; }
    body.single-artist > main > .clamped-width > section.flex-wrapper > .flex-row > article > a > .img {
      padding-bottom: 100%;
      margin-bottom: 1rem; }
  body.single-artist > main > .clamped-width > section.flex-wrapper > .flex-row > .dummy {
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    margin: 0 1rem 3rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0; }

body.single-exhibition > main > .clamped-width > .wysiwyg > .title {
  -ms-flex-flow: column;
  flex-flow: column;
  padding-bottom: 1.5rem; }
  body.single-exhibition > main > .clamped-width > .wysiwyg > .title > h1 {
    margin-top: 2rem; }
  body.single-exhibition > main > .clamped-width > .wysiwyg > .title > .subtitle {
    display: block;
    margin: 1rem 0 0;
    font-size: 1.4rem; }

body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section {
  margin: 2rem 0; }
  body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .no-results {
    text-align: center;
    padding: 3rem; }
  @media screen and (min-width: 87.3rem) {
    body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper {
      margin-top: 3rem; }
      body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper > .flex-row {
        margin: 0 -1rem; }
        body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper > .flex-row > article {
          max-width: 16rem; } }
  body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper > .flex-row > article {
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 1rem 2rem; }
    body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper > .flex-row > article a > h4 {
      text-align: center; }
    body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper > .flex-row > article a > .img {
      padding-bottom: 100%;
      margin-bottom: 1rem; }
  body.single-exhibition > main > .clamped-width > .wysiwyg > .primary > section > .flex-wrapper > .flex-row > .dummy {
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    margin: 0 1rem 2rem;
    max-width: 16rem; }

body.single-post > main > .clamped-width > .wysiwyg > h1 {
  margin: 3rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6ebf2; }

body.single-post > main > .clamped-width > .wysiwyg > .primary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-bottom: 3rem; }
  body.single-post > main > .clamped-width > .wysiwyg > .primary > .content {
    -ms-flex-preferred-size: 25rem;
    flex-basis: 25rem;
    -ms-flex-positive: 1;
    flex-grow: 1; }
    body.single-post > main > .clamped-width > .wysiwyg > .primary > .content p:first-of-type {
      margin-top: 0; }
  body.single-post > main > .clamped-width > .wysiwyg > .primary > .image.featured {
    -ms-flex-preferred-size: 20rem;
    flex-basis: 20rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 1.5rem;
    height: 31.25rem;
    background-repeat: no-repeat;
    background-size: contain; }

body.single-product > main > .wysiwyg > h1 {
  border-bottom: 1px solid #e6ebf2;
  padding: 3rem 0; }

body.single-product > main > .wysiwyg > .content > .woocommerce-message {
  margin-top: 2rem; }

body.single-product > main > .wysiwyg > .content > .product {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-top: 3.5rem;
  margin-bottom: 5rem; }
  body.single-product > main > .wysiwyg > .content > .product > .onsale {
    position: absolute;
    top: -1.5rem;
    left: 1rem;
    padding: 1.2rem;
    border-radius: 10%;
    color: #fff;
    background-color: #d74b2f; }
  body.single-product > main > .wysiwyg > .content > .product > .images {
    -ms-flex-preferred-size: 15rem;
    flex-basis: 15rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0; }
    body.single-product > main > .wysiwyg > .content > .product > .images > a > .img {
      padding-bottom: 50%;
      width: 100%;
      background-size: contain;
      background-position: center top;
      margin-bottom: 1rem; }
    body.single-product > main > .wysiwyg > .content > .product > .images > .enlarge_button {
      position: relative;
      padding-left: 1.5rem; }
      @media screen and (max-width: 87.3rem) {
        body.single-product > main > .wysiwyg > .content > .product > .images > .enlarge_button {
          display: block;
          margin: 1rem auto 2rem;
          width: 10rem; } }
      body.single-product > main > .wysiwyg > .content > .product > .images > .enlarge_button::before {
        content: "\f00e";
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.2rem;
        font-family: fontawesome;
        color: inherit; }
      body.single-product > main > .wysiwyg > .content > .product > .images > .enlarge_button:hover {
        cursor: pointer; }
  body.single-product > main > .wysiwyg > .content > .product > .summary {
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-flex-preferred-size: 32rem;
    flex-basis: 32rem;
    padding: 2rem; }
    @media screen and (max-width: 87.3rem) {
      body.single-product > main > .wysiwyg > .content > .product > .summary {
        margin: 2rem auto 0; } }
    body.single-product > main > .wysiwyg > .content > .product > .summary > .in-stock,
    body.single-product > main > .wysiwyg > .content > .product > .summary > .product_meta {
      display: none; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > .out-of-stock {
      display: block;
      text-align: center; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-info {
      padding-bottom: 3rem;
      margin-left: 0;
      border-bottom: 1px solid #e6ebf2; }
      @media screen and (min-width: 87.3rem) {
        body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-info {
          padding-right: 5rem; } }
      body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-info > li {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 1rem 0;
        font-size: 1.2rem;
        list-style-type: none;
        text-align: right; }
        body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-info > li.description {
          text-align: left;
          line-height: 1.2; }
        body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-info > li > span {
          padding-right: 1rem;
          font-family: Oswald, SourceSans light;
          font-weight: bold;
          color: #666666; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > .inquire {
      display: block;
      padding: 2rem 1rem;
      border-bottom: 1px solid #e6ebf2; }
      body.single-product > main > .wysiwyg > .content > .product > .summary > .inquire > a {
        position: relative;
        padding: 2rem 1rem;
        font-weight: bold;
        font-family: Oswald, SourceSans light;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 1rem; }
        body.single-product > main > .wysiwyg > .content > .product > .summary > .inquire > a::before {
          position: absolute;
          left: -1rem;
          top: 40%;
          font-family: fontawesome;
          color: #999999;
          content: "\f075"; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-left: 0;
      margin-bottom: 2rem;
      border-bottom: 1px solid #e6ebf2; }
      body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li {
        -ms-flex-preferred-size: 2rem;
        flex-basis: 2rem;
        padding: 2rem 0;
        list-style-type: none; }
        body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a {
          position: relative;
          text-decoration: none; }
          body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.share::before, body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.fb::before, body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.tw::before, body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.pn::before, body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.em::before {
            position: absolute;
            display: inline-block;
            font-family: fontawesome; }
          body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.share {
            padding: 0 1rem 0 2rem;
            color: #999999;
            text-transform: uppercase;
            font-weight: 700;
            font-family: Oswald, SourceSans light; }
            body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.share::before {
              left: 0rem;
              top: 20%;
              color: inherit;
              content: "\f1e0"; }
          body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.fb::before {
            color: #3b5998;
            content: "\f230"; }
          body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.tw::before {
            color: #00aced;
            content: "\f081"; }
          body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.pn::before {
            color: #cb2027;
            content: "\f0d3"; }
          body.single-product > main > .wysiwyg > .content > .product > .summary > ul.sidebar-social > li > a.em::before {
            color: #d74b2f;
            content: "\f199"; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > .cart .variations,
    body.single-product > main > .wysiwyg > .content > .product > .summary > .cart .quantity {
      display: none; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > .cart button[type="submit"] {
      position: relative;
      padding: 1rem 2rem 1rem 3rem; }
      body.single-product > main > .wysiwyg > .content > .product > .summary > .cart button[type="submit"]::before {
        position: absolute;
        left: 1.5rem;
        font-family: fontawesome;
        color: #ea9280;
        content: "\f07a"; }
    body.single-product > main > .wysiwyg > .content > .product > .summary > .product_meta {
      margin-top: 1rem; }

@media screen and (min-width: 87.3rem) {
  body.single-product > main > .wysiwyg > .content > section > .flex-wrapper {
    margin: 0 -1rem; } }

body.single-product > main > .wysiwyg > .content > section > .flex-wrapper > .flex-row {
  padding: 3rem 0; }
  body.single-product > main > .wysiwyg > .content > section > .flex-wrapper > .flex-row > article {
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 1rem 3rem; }
    body.single-product > main > .wysiwyg > .content > section > .flex-wrapper > .flex-row > article a > .img {
      padding-bottom: 100%;
      margin-bottom: 1rem; }
  body.single-product > main > .wysiwyg > .content > section > .flex-wrapper > .flex-row > .dummy {
    -ms-flex-preferred-size: 16rem;
    flex-basis: 16rem;
    margin: 0 1rem 3rem; }

body.page-template-page-two-col-slideshow > main > .wysiwyg > gecko-slideshow {
  max-width: 87.3rem;
  margin: 3rem auto 0; }

body.page-template-page-two-col-slideshow > main > .wysiwyg > .content {
  margin: 2rem 0; }
  body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap; }
    @media screen and (min-width: 87.3rem) {
      body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row {
        margin: 0 -1rem; } }
    body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left,
    body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right {
      -ms-flex-preferred-size: 25rem;
      flex-basis: 25rem;
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 1rem; }
      body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left > ul > li,
      body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right > ul > li {
        line-height: 2; }
    @media screen and (min-width: 87.3rem) {
      body.page-template-page-two-col-slideshow > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right {
        padding-left: 3rem; } }

body.page-template-page-two-col > main > .wysiwyg > .content {
  margin: 3rem 0; }
  body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    @media screen and (min-width: 87.3rem) {
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row {
        margin: 0 -1rem; } }
    body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left,
    body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right {
      -ms-flex-preferred-size: 35rem;
      flex-basis: 35rem;
      padding: 1rem; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left ul > li,
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right ul > li {
        margin-left: 1rem;
        padding: 0.3rem;
        line-height: 2; }
    body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right {
      position: relative;
      -ms-flex-preferred-size: 45rem;
      flex-basis: 45rem; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right > p {
        margin: 0; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form h3.gform_title {
        color: #d74b2f;
        font-size: 1.3rem;
        text-transform: uppercase;
        margin: 2rem 0; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body input,
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body textarea {
        border: none;
        border-bottom: 1px solid red;
        width: 100%; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body textarea {
        height: 50px; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body input {
        padding: 0.5rem 0.3rem; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul {
        margin: 0; }
        body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul li {
          display: inline-block;
          margin: 0 0 1rem;
          list-style-type: none; }
          body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul li:nth-of-type(1), body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul li:nth-of-type(2) {
            width: 48%; }
          body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul li:nth-of-type(1) {
            margin-right: 4%; }
          body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul li:nth-of-type(3) {
            width: 100%; }
          body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_body ul li.gform_validation_container {
            display: none; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end; }
        body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_footer input[type="submit"] {
          position: relative;
          width: 6rem; }
          body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .gform_footer input[type="submit"]::before {
            content: "\f003";
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(-50%);
            color: #e6ebf2;
            font-size: 1rem; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right form > .validation_error {
        padding: 1rem;
        background-color: rgba(215, 75, 47, 0.1);
        border: 1px solid #d74b2f; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right > .phone {
        position: absolute;
        left: 1rem;
        bottom: 3.5rem; }
        body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right > .phone > a {
          color: #666666;
          padding-left: 1rem;
          font-size: 2rem;
          font-weight: 600; }
        body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-right > .phone::before {
          content: "\f095";
          font-family: fontawesome;
          font-size: 1.4rem;
          color: #999999; }
    body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left {
      -ms-flex-preferred-size: 35rem;
      flex-basis: 35rem; }
      body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left > .highlighted {
        padding: 4rem 2rem;
        background-color: #e6ebf2; }
        body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left > .highlighted > h3 {
          margin-bottom: 1rem;
          text-transform: initial; }
        body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left > .highlighted > ul {
          margin: 0; }
          body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left > .highlighted > ul > li {
            position: relative;
            padding: 0.5rem 2.5rem;
            margin: 0;
            list-style-type: none;
            font-size: 1.3rem;
            color: #999999; }
            body.page-template-page-two-col > main > .wysiwyg > .content > .flex-wrapper > .flex-row > .col-left > .highlighted > ul > li::before {
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              content: "\f054";
              font-family: fontawesome;
              font-size: 1rem;
              color: #999999; }

body.login {
  background-color: #134282; }
  body.login div#login h1 a {
    background-image: url(../avatar.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 300px;
    height: 100px; }
