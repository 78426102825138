main {
	.wysiwyg {
		> .title {
			display: flex;
			justify-content: space-between;

			border-bottom: 1px solid $color-light-gray;

			padding: 3rem 0 3rem;

			@media screen and (max-width: $small-width) {
				display: block;

				padding: 1rem 0;

				text-align: center;

				> h1 {
					padding-bottom: 1rem;
				}
			}

			&.search {
				@media screen and (max-width: $small-width) {
					flex-flow: column wrap;
				}

				> form {
					position: relative;
					align-self: center;

					margin-top: 2rem;

					@media screen and (min-width: $row-width) {
						margin-top: 0;

						flex-basis: 20rem;
						align-self: flex-end;
					}

					> label {
						position: absolute;
						top: 0.5rem;
						right: 0;

						&[for="s"] {
							&:hover {
								cursor: pointer;
							}
						}

						&::after {
							display: inline-block;

							content: "\f002";

							font-family: fontawesome;
							color: $color-red;
							font-size: 1.2rem;
						}
					}

					> input[type="text"] {
						width: 100%;
						border: none;

						border-bottom: 1px solid $color-red;
						padding: 0.5rem 1.5rem 0.5rem 0;

						&:focus {
							outline: none;
						}
					}
				}
			}
		}
	}
}