body.page-template-page-two-col {
	> main {
		> .wysiwyg {
			> .content {
				margin: 3rem 0;

				> .flex-wrapper {
					> .flex-row {
						display: flex;
						flex-flow: row wrap;
						justify-content: space-between;

						@media screen and (min-width: $row-width) {
							margin: 0 -1rem;
						}

						> .col-left,
						> .col-right {
							flex-basis: 35rem;

							padding: 1rem;

							ul {
								> li {
									margin-left: 1rem;
									padding: 0.3rem;

									line-height: 2;
								}
							}
						}

						> .col-right {
							position: relative;

							flex-basis: 45rem;

							> p {
								margin: 0;
							}

							form {
								h3.gform_title {
									color: $color-red;

									font-size: 1.3rem;

									text-transform: uppercase;

									margin: 2rem 0;
								}

								> .gform_body {
									input,
									textarea {
										border: none;
										border-bottom: 1px solid red;
										width: 100%;
									}

									textarea {
										height: 50px;
									}

									input {
										padding: 0.5rem 0.3rem;
									}

									ul {
										margin: 0;

										li {
											display: inline-block;

											margin: 0 0 1rem;

											list-style-type: none;

											&:nth-of-type(1),
											&:nth-of-type(2), {
												width: 48%;
											}

											&:nth-of-type(1) {
												margin-right: 4%;
											}

											&:nth-of-type(3) {
												width: 100%;
											}

											&.gform_validation_container {
												display: none;
											}
										}
									}
								}

								> .gform_footer {
									display: flex;
									justify-content: flex-end;

									input[type="submit"] {
										position: relative;

										width: 6rem;

										&::before {
											content: "\f003";

											position: absolute;
											top: 0;
											left: 0;

											transform: translateY(-50%);

											color: $color-light-gray;
											font-size: 1rem;
										}
									}
								}

								> .validation_error {
									padding: 1rem;

									background-color: rgba($color-red, 0.1);
									border: 1px solid $color-red;
								}
							}

							> .phone {
								position: absolute;
								left: 1rem;
								bottom: 3.5rem;

								> a {
									color: $color-dark-gray;

									padding-left: 1rem;

									font-size: 2rem;
									font-weight: 600;
								}

								&::before {
									content: "\f095";

									font-family: fontawesome;
									font-size: 1.4rem;
									color: $color-gray;
								}
							}
						}

						> .col-left {
							flex-basis: 35rem;
							> .highlighted {
								padding: 4rem 2rem;

								background-color: $color-light-gray;

								> h3 {
									margin-bottom: 1rem;

									text-transform: initial
								}

								> ul {
									margin: 0;
									> li {
										position: relative;

										padding: 0.5rem 2.5rem;
										margin: 0;

										list-style-type: none;

										font-size: 1.3rem;

										color: $color-gray;

										&::before {
											position: absolute;
											top: 50%;
											left: 0;

											transform: translateY(-50%);

											content: "\f054";

											font-family: fontawesome;
											font-size: 1rem;
											color: $color-gray;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}